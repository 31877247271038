import Services from "./Services";

const CONTEXT_TRANSACTIONAL_LOG = "/log/getTransactionalLog";
const CONTEXT_FUNCTIONAL_LOG = "/logFunctional/getlog";
const CONTEXT_FUNCTIONAL_LOG_MASIVO = "/logFunctional/getlogmasivo";
const CONTEXT_PROCESSING_LOG = "/log/getProcessingLog";

const services = {
  logs: {
    

    getFuctionalLog(request){
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify(request);
      return Services.rest.callApiCC(CONTEXT_FUNCTIONAL_LOG, options);
    },

    getFuctionalLogmasivo(request){
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify(request);
      return Services.rest.callApi(CONTEXT_FUNCTIONAL_LOG_MASIVO, options);
    },

    getProcessingLog(request){
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify(request);
      return Services.rest.callApi(CONTEXT_PROCESSING_LOG, options);
    },

    getTransactionalLog(request) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify(request);
      return Services.rest.callApi(CONTEXT_TRANSACTIONAL_LOG, options);
    },
    getAdminLogsHeaders() {
      return [
        { label: "Id", key: "id" },
        { label: "Channel", key: "app" },
        { label: "Tipo de documento", key: "document_type" },
        { label: "Documento", key: "document" },
        { label: "Clasificacion", key: "classification" },
        { label: "Accion", key: "action" },
        { label: "Proceso", key: "process" },
        { label: "Id Transacción", key: "transactionalId" },
        { label: "Fecha", key: "creationDate" },
        { label: "Descripcion", key: "description" },
        { label: "Actor", key: "actor" },
        { label: "Codigo Transacción", key: "transactionResultCode" },
        { label: "Descripcion Transacción", key: "transactionResultDescription" },
        { label: "Source Id", key: "sourceId" },
        { label: "Zona Horaria", key: "timeZone" },
        { label: "ID Global", key: "globalId" },
        { label: "Valor Previo", key: "beforeValue" },
        { label: "Valor Resultante", key: "afterValue" },
        { label: "Información Adicional", key: "additionalInfo" }
      ];
    },

    getAdminLogHeaders() {
      return [
        { label: "Usuario", key: "userAdmin" },
        { label: "Rol", key: "roleAdmin" },
        { label: "Consumidor", key: "consumer" },
        { label: "CID", key: "cid" },
        { label: "AID", key: "aid" },
        { label: "Proceso", key: "process" },
        { label: "Fecha", key: "logDate" },
        { label: "Hora", key: "logTime" },
        { label: "Mensaje", key: "responseMessage" }
      ];
    },
    getProcessingLogHeaders(){
      return [
        { label: "Proceso", key: "process" },
        { label: "Fecha", key: "date"},
        { label: "Tipo de Documento", key:"documentType"},
        { label: "Documento", key: "document"},
        { label: "Primer Nombre", key: "firstName"},
        { label: "Segundo Nombre", key: "secondName"},
        { label: "Primer Apellido", key: "firstSurname"},
        { label: "Tipo de identidad", key: "identityType"},
        { label: "MDM Code", key: "mdmCode"},
        { label: "Resultado", key: "result"}
      ];
    }
  },
};

export default services;
