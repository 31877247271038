import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import Commons from "../../util/Commons";
import { Container, Grid } from "@material-ui/core";

class ParamsForm extends React.Component {
	constructor(props) {
		super();
		this.state = {
			form: {
				app : props.data ? props.data.app :"",
				id: props.data ? props.data.id : null,
				code: props.data ? props.data.code : "",
				value: props.data ? props.data.value : "",
				description: props.data ? props.data.description : ""
			},
			errorMessage: "",
			isModalOpen: false,
		};
	}

	handleChange = (e) => {
		this.setState({
			...this.state,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value,
			}
		});
	};

	handleOpenDialog = (e) => {
		if (this.validateForm()) {
			this.setState({
				...this.state,
				isModalOpen: true,
				errorMessage: "",
			});
		} else {
			this.setState({
				...this.state,
				errorMessage: "La justificación es requerida",
			});
		}
	};

	validateForm = () => {
		let response = true;
		if (Commons.validations.hasNotText(this.state.form.value)) {
			response = false;
		}
		return response;
	};

	handleCloseDialog = (e) => {
		this.setState({
			...this.state,
			isModalOpen: false,
		});
	};

	handleConfirm = (e) => {
		this.props.handleAccept(this.state.form);
	};


	handleKeyPressed = async (e) => {
		if (e.key === "Enter") {
			this.setState({
				isModalOpen: true,
			});
		}
	};

	render() {
		if (this.state.isModalOpen) {
			return (
				<ConfirmDialog
					open={this.state.isModalOpen}
					handleClose={this.handleCloseDialog}
					handleAccept={this.handleConfirm}
					title="¿Desea guardar el parametro?"
				/>
			);
		}

		return (
			<div>
				<Dialog fullWidth={true} maxWidth="md"
				open={this.props.open} onClose={this.props.handleClose}>
					<DialogTitle id="form-dialog-title">
						{this.state.form.id ? "Actualizar parametro" : "Registrar parametro"}
					</DialogTitle>
					<DialogContent>
						<Container maxWidth="md">
							<Grid container spacing={1} direction="column">
								<Grid item>
									<TextField
										name="code"
										label="Código*"
										value={this.state.form.code}
										onKeyPress={this.handleKeyPressed}
										onChange={this.handleChange}
										inputProps={{ maxLength: 30 }}
									/>
								</Grid>
								<Grid item>
									<TextField
										name="value"
										label="valor"
										fullWidth
										value={this.state.form.value}
										onKeyPress={this.handleKeyPressed}
										onChange={this.handleChange}
										inputProps={{ maxLength: 150 }}
									/>
								</Grid>
								<Grid item>
									<TextField
										name="description"
										label="Descripción"
										multiline
										fullWidth
										value={this.state.form.description}
										onKeyPress={this.handleKeyPressed}
										onChange={this.handleChange}
										inputProps={{ maxLength: 250 }}
									/>
								</Grid>
							</Grid>
						</Container>
						<br />
						<div>
							<p id="errorMessage" style={{ color: "red" }}>
								{this.state.errorMessage}
							</p>
						</div>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" size="small" onClick={this.props.handleClose}>Cancelar</Button>
						<Button variant="contained" size="small" color="primary" onClick={this.handleOpenDialog}>
							Guardar
            </Button>
					</DialogActions>
				</Dialog>
			</div >
		);
	}
}

export default withRouter((ParamsForm));
