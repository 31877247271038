import AADService from "./AADService";
const SERVER = process.env.REACT_APP_API_DOMAIN;

async function callApi(endpoint, options) {
  options.headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AADService.auth.getToken(),
  };
  let url = SERVER + endpoint;
  let data;
  let response;
  try {
    response = await fetch(url, options);
    if(!response.ok){
      if(response.status === 401){
        AADService.auth.logout();
      }
      throw new Error(response.statusText);
    }
    data = await response.json();
  } catch (err) {
    return {
      data: null,
      message: "Error en el proceso",
      success: false,
      status: 500
    }
  }
  return data;
}

async function callApiCC(endpoint, options) {
  options.headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AADService.auth.getToken(),
  };
  let url = SERVER + endpoint;
  let data;
  let response;
  try {
    response = await fetch(url, options);
    if(!response.ok){
      if(response.status === 401){
        AADService.auth.logout();
      }
      data = await response.json();
      return {
        data: null,
        message: data.message,
        success: false,
        status: data.status
      }
    }
    data = await response.json();
  } catch (err) {
    return {
      data: null,
      message: "Error en el proceso",
      success: false,
      status: 500
    }
  }
  return data;
}

const services = {
  rest: {
    callApi(endpoint, options) {
      return callApi(endpoint, options);
    },
    callApiCC(endpoint, options) {
      return callApiCC(endpoint, options);
    },
  },
};

export default services;
