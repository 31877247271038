import React from "react";
import Services from "../../services/LogsService";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { Breadcrumbs, CircularProgress, Typography } from "@material-ui/core";
import SelectDocumentType from "../../components/Selects/SelectDocumentType";
import Modal from "../../components/Modal/Modal";
import FunctionalLogsResults from "./FunctionalLogsResults";
import Commons from "../../util/Commons";
import AADService from "../../services/AADService";
import DownloadCsv from "../../components/DownloadCsv/DownloadCsv";

const useStyles = Commons.styles.getStyles();

class FunctionalLog extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      modalIsOpen: false,
      modalMessage: "",
      valornumber:"",
      valorid:"",
      valordoc:"",
      value:0,
      form: {
        process: "",
        transactionalId: "",
        documentType: "",
        document: "",
        app : "",
        startDate: Commons.date.getIsoStartOfDayDateTime(),
        endDate: Commons.date.getIsoCurrentDate(),
        classification:"",
        action:"",
        actor:""
      },
      formasivo: {
        process: "",
        transactionalId: "",
        documentType: "",
        document: "",
        app : "",
        startDate: Commons.date.getIsoStartOfDayDateTimeini(),
        endDate: Commons.date.getIsoCurrentDate(),
        classification:"",
        action:"",
        actor:""
      },
    };
    this.baseState = this.state;
    
  
  }
  componentDidMount() {
    this.setState({ loading: false });
  }

  onClickSearchmasivo = async () => {
    const actor = this.state.formasivo.documentType + this.state.formasivo.document;
    this.setState({
      loading: true,
      formasivo: Object.assign({}, this.state.formasivo, { actor })
    });

    if(this.state.formasivo.startDate === "" || this.state.formasivo.endDate === "" || 
     this.state.formasivo.app === ""){

      this.setState({
        loading: false,
        resultsIsOpen: false,
        data: null,
        modalIsOpen: true,
        modalMessage: "Faltan datos requeridos *"
      });
   
    } else{
      if(this.state.formasivo.startDate > this.state.formasivo.endDate ){
        this.setState({
          loading: false,
          resultsIsOpen: false,
          data: null,
          modalIsOpen: true,
          modalMessage: "La fecha de inicio debe ser menor a la fecha de fin"
        });
     
      }else{
      this.setState({
        loading: true
      });
      const response = await Services.logs.getFuctionalLogmasivo(
      this.state.formasivo,
      this.props.userName
    );
    if (response.data != null) {
      let i = response.data.length;
      let j;
      const NuevaInfo = [];
      for(j=0; j < i; j++){
        NuevaInfo[j] = JSON.stringify(response.data[j].additionalInfo);
        response.data[j].additionalInfo = NuevaInfo[j];
      }
      this.setState({
        loading: false,
        resultsIsOpen: true,
        data: response.data,
      });
    } else {
      this.setState({
        loading: false,
        resultsIsOpen: false,
        data: null,
        modalIsOpen: true,
        modalMessage: response.message,
      });
    }
  }
  }
  };

  onClickSearch = async () => {
    const actor = this.state.form.documentType + this.state.form.document;
    this.setState({
      loading: true,
      form: Object.assign({}, this.state.form, { actor })
    });

    if(this.state.form.startDate === "" || this.state.form.endDate === "" || 
     this.state.form.documentType === "" || this.state.form.document === "" ||
     this.state.form.app === ""){

      this.setState({
        loading: false,
        resultsIsOpen: false,
        data: null,
        modalIsOpen: true,
        modalMessage: "Faltan datos requeridos *"
      });
   
    } else{
      if(this.state.form.startDate > this.state.form.endDate ){
        this.setState({
          loading: false,
          resultsIsOpen: false,
          data: null,
          modalIsOpen: true,
          modalMessage: "La fecha de inicio debe ser menor a la fecha de fin"
        });
     
      }else{
      this.setState({
        loading: true
      });
      const response = await Services.logs.getFuctionalLog(
        this.state.form,
        this.props.userName
    );
    if (response.success && response.data != null){
      let i = response.data.length;
      let j;
      const NuevaInfo = [];
      for(j=0; j < i; j++){
        NuevaInfo[j] = JSON.stringify(response.data[j].additionalInfo);
        response.data[j].additionalInfo = NuevaInfo[j];
      }
      this.setState({
        loading: false,
        resultsIsOpen: true,
        data: response.data,
      });
    } else {
      this.setState({
        loading: false,
        resultsIsOpen: false,
        data: null,
        modalIsOpen: true,
        modalMessage: response.message,
      });
    }
  }
  }
  };

  handleClear = () => {
    this.setState(this.baseState);
    this.setState({ loading: false });
  };

  handleClearmasivo = () => {
    this.setState(this.baseState);
    this.setState({ loading: false });
  };

  handleChange = (_, value) => {
    this.setState({
      value,
    });
  };

  onChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });

  };

  onChanges = (e) => {
    this.setState({
      formasivo: {
        ...this.state.formasivo,
        [e.target.name]: e.target.value,
      },
    });

  };

  handleCloseModal = (e) => {
    this.setState({ modalIsOpen: false });
  };

  handleCloseResults = (e) => {
    this.setState({ resultsIsOpen: false });
  };

  render() {
    const { classes } = this.props;
    if (this.state.loading === true) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }

    if (this.state.modalIsOpen) {
      return (
        <Modal
          isOpen={this.state.modalIsOpen}
          onClose={this.handleCloseModal}
          title="Log Funcional"
          message={this.state.modalMessage}
        />
      );
    }

   
    return (
      <React.Fragment>
        <Breadcrumbs className={classes.breadcrumbs}>
          <Typography variant="h5" color="primary">
            SUID
          </Typography>
          <Typography variant="h5" color="textPrimary">
            Log Funcional
          </Typography>
        </Breadcrumbs>
        <Container component="main" maxWidth="md">


    <Tabs
    value={this.state.value}
    onChange={this.handleChange}
    indicatorColor="primary"
    textColor="primary"
    centered
    >
    <Tab label="LOG"/>
    <Tab label="LOG MASIVO"/>
    </Tabs>

    {this.state.value === 0 && (
          <>
              <Grid container className={classes.container}>
            <Grid item xs={3} className={classes.formControl}>

                <TextField
                size="small"
                fullWidth
                label="Proceso"
                name="process"
                value={this.state.form.process}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={3} className={classes.formControl}>
            <TextField
                size="small"
                fullWidth
                label="Id Transacción"
                name="transactionalId"
                value={this.state.form.transactionalId}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={3} className={classes.formControl}>
              <SelectDocumentType
              id="documentType"
              name="documentType"
              label="Tipo de documento *"
              value={this.state.form.documentType}
              onChange={this.onChange}
              required
              />
            </Grid>
            <Grid item xs={2} className={classes.formControl}>
              <TextField
                size="small"
                fullWidth
                label="Documento"
                name="document"
                type="number"
                value={this.state.form.document}
                onChange={this.onChange}
                required
              />
            </Grid>
            <Grid item xs={3} className={classes.formControl}>
              <TextField
                size="small"
                fullWidth
                label="Canal"
                name="app"
                value={this.state.form.app}
                onChange={this.onChange}
                required
              />
            </Grid>
            <Grid item xs={3} className={classes.formControl}>
              <TextField
                size="small"
                fullWidth
                label="Acción"
                name="action"
                value={this.state.form.action}
                onChange={this.onChange}
              />
            </Grid>
           
            <Grid item xs={5} className={classes.formControl}>
              <TextField
                id="startDate"
                name="startDate"
                required
                label="Fecha inicio"
                type="datetime-local"
                value={this.state.form.startDate}
                onChange={this.onChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={5} className={classes.formControl}>
              <TextField
                id="endDate"
                name="endDate"
                required
                label="Fecha fin"
                type="datetime-local"
                value={this.state.form.endDate}
                onChange={this.onChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
        
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-end">
              <Grid item>
                <Button
                  id="btnClearFunctionalLog"
                  size="small"
                  variant="contained"
                  onClick={this.handleClear}
                >
                  Limpiar
          </Button>
              </Grid>
              {AADService.auth.hasAccessToResource('LogFuncional', 'Buscar') &&
              <Grid item>
                <Button
                  id="btnSearchFunctionalLog"
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={this.onClickSearch}
                >
                  Buscar
          </Button>
              </Grid>
                }
            </Grid>
          </Grid>

          </>
        )}

{this.state.value === 1 && (
          <>
              <Grid container className={classes.container}>
            <Grid item xs={3} className={classes.formControl}>

                <TextField
                size="small"
                fullWidth
                label="Proceso"
                name="process"
                value={this.state.formasivo.process}
                onChange={this.onChanges}
              />
            </Grid>            
            <Grid item xs={3} className={classes.formControl}>
              <TextField
                size="small"
                fullWidth
                label="Canal"
                name="app"
                value={this.state.formasivo.app}
                onChange={this.onChanges}
                required
              />
            </Grid>
            <Grid item xs={3} className={classes.formControl}>
              <TextField
                size="small"
                fullWidth
                label="Acción"
                name="action"
                value={this.state.formasivo.action}
                onChange={this.onChanges}
              />
            </Grid>
           
            <Grid item xs={5} className={classes.formControl}>
              <TextField
                id="startDate"
                name="startDate"
                required
                label="Fecha inicio"
                type="datetime-local"
                value={this.state.formasivo.startDate}
                onChange={this.onChanges}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={5} className={classes.formControl}>
              <TextField
                id="endDate"
                name="endDate"
                label="Fecha fin"
                type="datetime-local"
                value={this.state.formasivo.endDate}
                onChange={this.onChanges}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
        
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-end">
              <Grid item>
                <Button
                  id="btnClearFunctionalLog"
                  size="small"
                  variant="contained"
                  onClick={this.handleClearmasivo}
                >
                  Limpiar
          </Button>
              </Grid>
              {AADService.auth.hasAccessToResource('LogFuncional', 'Buscar') &&
              <Grid item>
                <Button
                  id="btnSearchFunctionalLog"
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={this.onClickSearchmasivo}
                >
                  Buscar
          </Button>
              </Grid>
                }
            </Grid>
          </Grid>

          </>
        )}

      
        </Container>
        <div className={classes.table}>
          {this.state.resultsIsOpen &&
            this.state.data.length <= 1000 &&
            FunctionalLogsResults(
              this.state.resultsIsOpen,
              this.state.data,
              this.handleCloseResults
            )}
        </div>
        <div className={classes.table}>
          {this.state.resultsIsOpen &&
            this.state.data.length > 1000 &&
            <DownloadCsv
              title="Resultados Log Funcional"
              open={this.state.resultsIsOpen}
              handleClose={this.handleCloseResults}
              data={this.state.data}
              size={this.state.data.length}
              last={this.state.data[this.state.data.length-1].creationDate}
              headers={Services.logs.getAdminLogsHeaders()}
            />}
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(useStyles)(FunctionalLog);
