import Services from "./Services";

const SAVE_CONTEXT = "/consumer/save";
const GET_CONTEXT = "/consumer/getAll";

const services = {
  consumer: {
    saveConsumer(request) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify(request);
      return Services.rest.callApi(SAVE_CONTEXT, options);
    },
    getConsumers() {
      var options = {};
      options.method = "GET";
      return Services.rest.callApi(GET_CONTEXT, options);
    }
  },
};

export default services;
