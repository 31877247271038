import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import Commons from "../../util/Commons";
import { Container, Grid, InputLabel, Select } from "@material-ui/core";

class JustificationForm extends Component {
	constructor(props) {
		super();
		this.state = {
			form: {
				id: props.data ? props.data.id : null,
				data: props.data ? props.data.data : "",
				page: props.data ? props.data.page : "IDENTIDAD",
				process: props.data ? props.data.process : "ACTIVAR IDENTIDAD",
			},
			processSelect: [],
			errorMessage: "",
			isModalOpen: false,
		};
		if (this.state.form.page === "IDENTIDAD") {
			this.state.processSelect = Commons.process.getIdentityProcess();
		} else if (this.state.form.page === "ALIAS") {
			this.state.processSelect = Commons.process.getAliasProcess();
		}
	}

	handleChange = (e) => {
		this.setState({
			...this.state,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value,
			}
		});
	};

	handleChangePage = (e) => {
		let select = [];
		if (e.target.value === "IDENTIDAD") {
			select = Commons.process.getIdentityProcess();
		} else if (e.target.value === "ALIAS") {
			select = Commons.process.getAliasProcess();
		}
		this.setState({
			...this.state,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value,
				process: select[0].value
			},
			processSelect: select
		});
	};

	handleOpenDialog = (e) => {
		let validationMessage = this.validateForm();
		if (Commons.validations.hasNotText(validationMessage)) {
			this.setState({
				...this.state,
				isModalOpen: true,
				errorMessage: "",
			});
		} else {
			this.setState({
				...this.state,
				errorMessage: validationMessage,
			});
		}
	};

	validateForm = () => {
		let errors = "";
		if (Commons.validations.hasNotText(this.state.form.data)) {
			errors += "Justificación";
		}

		if (Commons.validations.hasNotText(this.state.form.page)) {
			errors += errors === "" ? "Página" : ", Página";
		}

		if (Commons.validations.hasNotText(this.state.form.process)) {
			errors += errors === "" ? "Proceso" : ", Proceso";
		}

		if (errors !== "") {
			errors = "Los siguientes datos son requeridos: " + errors;
		}
		return errors;
	};

	handleCloseDialog = (e) => {
		this.setState({
			...this.state,
			isModalOpen: false,
		});
	};

	handleConfirm = (e) => {
		this.props.handleAccept(this.state.form);
	};

	handleKeyPressed = async (e) => {
		if (e.key === "Enter") {
			this.setState({
				isModalOpen: true,
			});
		}
	};

	render() {
		if (this.state.isModalOpen) {
			return (
				<ConfirmDialog
					open={this.state.isModalOpen}
					handleClose={this.handleCloseDialog}
					handleAccept={this.handleConfirm}
					title="¿Desea guardar la justificación?"
				/>
			);
		}

		return (
			<div>
				<Dialog fullWidth={true}
					maxWidth="md" open={this.props.open} onClose={this.props.handleClose}>
					<DialogTitle id="form-dialog-title">
						{this.state.form.id ? "Actualizar justificación" : "Registrar justificación"}
					</DialogTitle>
					<DialogContent>
						<Container maxWidth="md">
							<Grid container spacing={1} >
								<Grid item xs={12}>
									<TextField
										autoFocus
										autoComplete="off"
										name="data"
										label="Justificación*"
										fullWidth
										value={this.state.form.data}
										onKeyPress={this.handleKeyPressed}
										onChange={this.handleChange}
										inputProps={{ maxLength: 150 }}
									/>
								</Grid>
								<Grid item xs={3}>
									<InputLabel id="pageLabel">Página*</InputLabel>
									<Select
										native
										labelId="pageLabel"
										name="page"
										margin="dense"
										fullWidth
										value={this.state.form.page}
										onChange={this.handleChangePage}
										displayEmpty={true}
									>
										<option key="1" value="IDENTIDAD">
											IDENTIDAD
                  		</option>
										<option key="2" value="ALIAS">
											ALIAS
                  		</option>
									</Select>
								</Grid>
								<Grid item xs={4}>
									<InputLabel id="processLabel">Proceso*</InputLabel>
									<Select
										native
										labelId="processLabel"
										name="process"
										margin="dense"
										fullWidth
										value={this.state.form.process}
										onChange={this.handleChange}
										displayEmpty={true}
									>
										{this.state.processSelect.map((data) => (
											<option key={data.key} value={data.value}>
												{data.process}
											</option>
										))}
									</Select>
								</Grid>
							</Grid>
						</Container>
						<br />
						<div>
							<p id="errorMessage" style={{ color: "red" }}>
								{this.state.errorMessage}
							</p>
						</div>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" size="small" onClick={this.props.handleClose}>Cancelar</Button>
						<Button variant="contained" size="small" color="primary" onClick={this.handleOpenDialog}>
							Guardar
            </Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default withRouter(JustificationForm);
