import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import Tooltip from "@material-ui/core/Tooltip";
import AADService from "../../services/AADService";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  tabLabel: { fontSize: "10px", fontWeight: "bold" },
  tabIcon: {
    marginTop: "-20px",
  },
});

export default function IconTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  let history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onTabClick = (path, e) => {
    history.push(path);
  };


  return (
    <Paper square className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        style={{ height: "50px" }}
        centered
      >
        {AADService.auth.hasAccessToResource('Identidad', 'Buscar') &&
          <Tooltip title="Identidad">
            <Tab
              style={{ minWidth: "100px" }}
              id="btnIdentityPage"
              label={<span className={classes.tabLabel}>Identidad</span>}
              icon={
                <PersonPinIcon fontSize="small" className={classes.tabIcon} />
              }
              onClick={(e) => onTabClick("/identity", e)}
            />
          </Tooltip>
        }
        {AADService.auth.hasAccessToResource('Alias', 'Buscar') &&
          <Tooltip title="Alias">
            <Tab
              style={{ minWidth: "100px" }}
              id="btnAliasPage"
              label={<span className={classes.tabLabel}>Alias</span>}
              icon={
                <PeopleAltIcon fontSize="small" className={classes.tabIcon} />
              }
              onClick={(e) => onTabClick("/alias", e)}
            />
          </Tooltip>
        }
        {AADService.auth.hasAccessToResource('LogAdministrativo', 'Buscar') &&
          <Tooltip title="Log Administrativo">
            <Tab
              style={{ minWidth: "100px" }}
              id="btnAdminLogPage"
              label={<span className={classes.tabLabel}>Log Administrativo</span>}
              icon={
                <FindReplaceIcon fontSize="small" className={classes.tabIcon} />
              }
              onClick={(e) => onTabClick("/adminLog", e)}
            />
          </Tooltip>
        }
        {AADService.auth.hasAccessToResource('LogTransaccional', 'Buscar') &&
          <Tooltip title="Log Transaccional">
            <Tab
              style={{ minWidth: "100px" }}
              id="btnTransactionalLogPage"
              label={<span className={classes.tabLabel}>Log Transaccional</span>}
              icon={
                <FindInPageIcon fontSize="small" className={classes.tabIcon} />
              }
              onClick={(e) => onTabClick("/transactionalLog", e)}
            />
          </Tooltip>
        }
        {
          AADService.auth.hasAccessToResource('LogTransaccional', 'Buscar') &&
          <Tooltip title="Log De Procesamiento">
            <Tab
              style={{ minWidth: "100px" }}
              id="btnProcessingLogPage"
              label={<span className={classes.tabLabel}>Log De Procesamiento</span>}
              icon={
                <FindInPageIcon fontSize="small" className={classes.tabIcon} />
              }
              onClick={(e) => onTabClick("/processingLog", e)}
            />
          </Tooltip>
        }
        {AADService.auth.hasAccessToResource('Consumidores', 'Consultar') &&
          <Tooltip title="Consumidores">
            <Tab
              style={{ minWidth: "100px" }}
              id="btnConsumerPage"
              label={<span className={classes.tabLabel}>Consumidores</span>}
              icon={
                <SupervisedUserCircleIcon
                  fontSize="small"
                  className={classes.tabIcon}
                />
              }
              onClick={(e) => onTabClick("/consumer", e)}
            />
          </Tooltip>
        }
        {AADService.auth.hasAccessToResource('Segmentos', 'Consultar') &&
          <Tooltip title="Agrupacion">
            <Tab
              style={{ minWidth: "100px" }}
              id="btnSegmentsPage"
              label={<span className={classes.tabLabel}>Agrupacion</span>}
              icon={
                <CreateNewFolderIcon
                  fontSize="small"
                  className={classes.tabIcon}
                />
              }
              onClick={(e) => onTabClick("/segments", e)}
            />
          </Tooltip>
        }
        {AADService.auth.hasAccessToResource('TiposDocumentos', 'Consultar') &&
          <Tooltip title="Tipos de documentos">
            <Tab
              style={{ minWidth: "100px" }}
              id="btnDocumentTypesPage"
              label={<span className={classes.tabLabel}>Tipos de documentos</span>}
              icon={
                <CreateNewFolderIcon
                  fontSize="small"
                  className={classes.tabIcon}
                />
              }
              onClick={(e) => onTabClick("/documentTypes", e)}
            />
          </Tooltip>
        }
        {AADService.auth.hasAccessToResource('Justificaciones', 'Consultar') &&
          <Tooltip title="Justificaciones">
            <Tab
              style={{ minWidth: "100px" }}
              id="btnJustificationsPage"
              label={<span className={classes.tabLabel}>Justificaciones</span>}
              icon={
                <CreateNewFolderIcon
                  fontSize="small"
                  className={classes.tabIcon}
                />
              }
              onClick={(e) => onTabClick("/justifications", e)}
            />
          </Tooltip>
        }
        {AADService.auth.hasAccessToResource('LogFuncional', 'Buscar') &&
          <Tooltip title="Log Funcional">
            <Tab
              style={{ minWidth: "100px" }}
              id="btnFunctionalLogPage"
              label={<span className={classes.tabLabel}>Log Funcional</span>}
              icon={
                <FindReplaceIcon fontSize="small" className={classes.tabIcon} />
              }
              onClick={(e) => onTabClick("/functional", e)}
            />
          </Tooltip>
        }
        {AADService.auth.hasAccessToResource('Contactos', 'Buscar') &&
          <Tooltip title="Contactos">
            <Tab
              style={{ minWidth: "100px" }}
              id="btnContactosPage"
              label={<span className={classes.tabLabel}>Contactos</span>}
              icon={
                <PeopleAltIcon
                  fontSize="small"
                  className={classes.tabIcon}
                />
              }
              onClick={(e) => onTabClick("/contacts", e)}
            />
          </Tooltip>
        }
        {AADService.auth.hasAccessToResource('Params', 'LeerParams') &&
        <Tooltip title="Parametros">
          <Tab
            style={{ minWidth: "110px", }}
            id="btnParamsPage"
            label={<span className={classes.tabLabel}>Parametros</span>}
            icon={
              <CreateNewFolderIcon
                fontSize="small"
                className={classes.tabIcon}
              />
            }
            onClick={(e) => onTabClick("/paramstarjetas", e)}
          />
        </Tooltip>
        }
      </Tabs>
    </Paper>
  );
}
