import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MaterialTable from "material-table";
import Commons from "../../util/Commons";
import AADService from "../../services/AADService";

export default function ContactsResults(open, data, handleClose) {
  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogContent>
          <MaterialTable
            title="Resultados Contactos"
            icons={Commons.materialTable.getIcons()}
            data={data}
            options={
              Commons.materialTable.getOptions(true, true, AADService.auth.hasAccessToResource('Contactos', 'Exportar'))
            }
            localization={Commons.materialTable.getLocalization()}
            columns={[                      
              {
                title: "ID",
                field: "id",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "CID",
                field: "cid",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Nombre Cliente",
                field: "clientName",
                editable: "never",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Tipo de documento",
                field: "documentType",
                editable: "never",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Documento",
                field: "document",
                editable: "never",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Alias",
                field: "contactAlias",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Código país",
                field: "cellIndicator",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Celular",
                field: "cellNumber",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Correo",
                field: "email",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Estado",
                field: "status",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Fecha creación",
                field: "dateCreate",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Fecha eliminación",
                field: "dateDelete",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Fecha última actualización",
                field: "dateUpdate",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Canal creación",
                field: "consumerCreate",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Canal eliminación",
                field: "consumerDelete",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Canal última actualización",
                field: "consumerUpdate",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "OTP generados",
                field: "amountOtpGenerated",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
                searchable: false,
                hidden: !(AADService.auth.getRoleName().toUpperCase() === 'SUPERUSUARIO' || AADService.auth.getRoleName().toUpperCase() === 'SEGURIDAD'),
              },
              {
                title: "Fecha último OTP",
                field: "lastOtpGenerated",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
                hidden: !(AADService.auth.getRoleName().toUpperCase() === 'SUPERUSUARIO' || AADService.auth.getRoleName().toUpperCase() === 'SEGURIDAD'),
              },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={handleClose}
            color="primary"
            autoFocus
          >
            Regresar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
