import React from "react";
import Services from "../../services/LogsService";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { Breadcrumbs, CircularProgress, Typography } from "@material-ui/core";
import SelectConsumer from "../../components/Selects/SelectConsumer";
import SelectDocumentType from "../../components/Selects/SelectDocumentType";
import SelectTransaction from "../../components/Selects/SelectTransaction";
import Modal from "../../components/Modal/Modal";
import TransactionalLogResults from "./TransactionalLogsResults";
import Commons from "../../util/Commons";
import DownloadCsv from "../../components/DownloadCsv/DownloadCsv";

const useStyles = Commons.styles.getStyles();

class TransactionalLog extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      modalIsOpen: false,
      modalMessage: "",
      resultsIsOpen: false,
      form: {
        consumer: "",
        transaction: "",
        documentType: "",
        document: "",
        startDate: Commons.date.getIsoStartOfDayDate(),
        endDate: Commons.date.getIsoCurrentDate(),
      },
    };
    this.baseState = this.state;
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  onClickSearch = async (e) => {
    this.setState({ loading: true });
  
    const response = await Services.logs.getTransactionalLog(
      this.state.form,
      this.props.userName
    );
    if (response.data != null) {
      this.setState({
        loading: false,
        resultsIsOpen: true,
        data: response.data,
      });
    } else {
      this.setState({
        loading: false,
        resultsIsOpen: false,
        data: null,
        modalIsOpen: true,
        modalMessage: response.message,
      });
    }
  };

  handleClear = () => {
    this.setState(this.baseState);
    this.setState({ loading: false });
  };

  onChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleCloseModal = (e) => {
    this.setState({ modalIsOpen: false });
  };

  handleCloseResults = (e) => {
    this.setState({ resultsIsOpen: false });
  };

  render() {
    const { classes } = this.props;

    if (this.state.loading === true) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }

    if (this.state.modalIsOpen) {
      return (
        <Modal
          isOpen={this.state.modalIsOpen}
          onClose={this.handleCloseModal}
          title="Log Transaccional"
          message={this.state.modalMessage}
        />
      );
    }

    return (
      <React.Fragment>
        <Breadcrumbs className={classes.breadcrumbs}>
          <Typography variant="h5" color="primary">
            SUID
          </Typography>
          <Typography variant="h5" color="textPrimary">
            Log Transaccional
          </Typography>
        </Breadcrumbs>
        <Container component="main" maxWidth="md">
          <Grid container className={classes.container}>
            <Grid item xs={3} className={classes.formControl}>
              <SelectConsumer
                consumer={this.state.form.consumer}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={3} className={classes.formControl}>
              <SelectTransaction
                value={this.state.form.transaction}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={3} className={classes.formControl}>
              <SelectDocumentType
                value={this.state.form.documentType}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={2} className={classes.formControl}>
              <TextField
                size="small"
                fullWidth
                label="Documento"
                name="document"
                value={this.state.form.document}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={4} className={classes.formControl}>
              <TextField
                id="startDate"
                name="startDate"
                label="Fecha inicio"
                type="datetime-local"
                value={this.state.form.startDate}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={4} className={classes.formControl}>
              <TextField
                id="endDate"
                name="endDate"
                label="Fecha fin"
                type="datetime-local"
                value={this.state.form.endDate}
                onChange={this.onChange}
              />
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-end">
              <Grid item>
                <Button
                  id="btnClearTransactionalLog"
                  size="small"
                  variant="contained"
                  onClick={this.handleClear}
                >
                  Limpiar
          </Button>
              </Grid>
              <Grid item>
                <Button
                  id="btnSearchTransactionalLog"
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={this.onClickSearch}
                >
                  Buscar
          </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <div className={classes.table}>
          {this.state.resultsIsOpen &&
            this.state.data.length <= 1000 &&
            TransactionalLogResults(
              this.state.resultsIsOpen,
              this.state.data,
              this.handleCloseResults
            )}
        </div>
        <div className={classes.table}>
          {this.state.resultsIsOpen &&
            this.state.data.length > 1000 &&
            <DownloadCsv
              title="Resultados Log Transaccional"
              open={this.state.resultsIsOpen}
              handleClose={this.handleCloseResults}
              data={this.state.data}
              headers={Services.logs.getAdminLogHeaders()}
            />}
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(useStyles)(TransactionalLog);
