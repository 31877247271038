import Services from "./Services";

const GET_CONTEXT = "/contact/findByTypeAndDocument";
const GET_ALL_CONTEXT = "/contact/getAll";
const CHANGE_STATUS_CONTEXT = "/contact/changeStatus";
const REVOKE_CONTEXT = "/contact/revoke";
const GENERATE_OPT_CONTEXT = "/contact/generateOtp";
const VALIDATE_OPT_CONTEXT = "/contact/validateOtp";
const VALIDATE_INFO_CONTEXT = "/contact/validateInfo";

const services = {
  contact: {
    findByTypeAndDoc(request) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify(request);
      return Services.rest.callApiCC(GET_CONTEXT, options);
    },
    getContactsAll(request) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify(request);
      return Services.rest.callApiCC(GET_ALL_CONTEXT, options);
    },
    getContactsHeaders(all) {
      var headers = [
        { label: "ID", key: "id" },
        { label: "CID", key: "cid" },
        { label: "Nombre cliente", key: "clientName" },
        { label: "Tipo documento", key: "documentType" },
        { label: "Documento", key: "document" },
        { label: "Alias contacto", key: "contactAlias" },
        { label: "Código país", key: "cellIndicator" },
        { label: "Celular", key: "cellNumber" },
        { label: "email", key: "email" },        
        { label: "Estado", key: "status" },
        { label: "Fecha creación", key: "dateCreate" },
        { label: "Fecha eliminación", key: "dateDelete" },
        { label: "Fecha última actualización", key: "dateUpdate" },
        { label: "Canal creación", key: "consumerCreate" },
        { label: "Canal eliminación", key: "consumerDelete" },
        { label: "Canal última actualización", key: "consumerUpdate" }  
      ];
      if(!all) {
        return headers;
      }
      headers.push({ label: "OTP generados", key: "amountOtpGenerated" });
      headers.push({ label: "Fecha último OTP", key: "lastOtpGenerated" });
      return headers;
      
    },
    changeStatus(id, status) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify({
        id: id,
        status: status
      });
      return Services.rest.callApiCC(CHANGE_STATUS_CONTEXT, options);
    },
    revoke(justification, id) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify({
        idContact: id,
        justification: justification,
      });
      return Services.rest.callApiCC(REVOKE_CONTEXT, options);
    },
    generateOtp(id) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify({
        idContact: id
      });
      return Services.rest.callApiCC(GENERATE_OPT_CONTEXT, options);
    },
    validateOtp(id, otp) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify({
        idContact: id,
        otpCode: otp
      });
      return Services.rest.callApiCC(VALIDATE_OPT_CONTEXT, options);
    },
    validateContactInfo(id, email, cell) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify({
        idContact: id,
        email: email,
        cellnumber: cell
      });
      return Services.rest.callApiCC(VALIDATE_INFO_CONTEXT, options);
    },
  },
};

export default services;
