import React from "react";
import Services from "../../services/SegmentService";
import Select from "@material-ui/core/Select";
import { InputLabel } from "@material-ui/core";

class SelectSegment extends React.Component {
	constructor() {
		super();
		this.state = {
			data: [{
				id: "",
				name: "",
			}],
		};
	}

	async componentDidMount() {
		const response = await Services.segment.getSegments("");
		if (response.success && response.data != null) {
			this.setState({
				data: this.state.data.concat(response.data),
			});
		}
	}

	render() {
		return (
			<React.Fragment>
				<InputLabel htmlFor="segment" id="segmentLabel">Agrupación</InputLabel>
				<Select
					native
					labelId="segmentLabel"
					name="segment"
					id="segment"
					value={this.props.value}
					onChange={this.props.onChange}
					displayEmpty={true}
					fullWidth
				>
					{this.state.data.map((data) => (
						<option key={data.id} value={data.id}>
							{data.name}
						</option>
					))}
				</Select>
			</React.Fragment>
		);
	}
}
export default SelectSegment;
