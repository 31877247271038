import { Account, ClientAuthError } from "msal";
import { GRAPH_ENDPOINTS, GRAPH_REQUESTS, msalApp } from "../util/msalConfig";

const AADService = {
  auth: {
    LOGOUT_ENDPOINT: process.env.REACT_APP_LOGOUT_ENDPOINT,

    async init() {
      let loginResponse;
      let graphResponse;

      try {
        [loginResponse, graphResponse] = await Promise.all([
          msalApp.acquireTokenSilent(GRAPH_REQUESTS.LOGIN),
          msalApp
            .acquireTokenSilent(GRAPH_REQUESTS.GRAPH_LOGIN)
            .catch(_err => null)
        ]);
      } catch (e) {
        if (e instanceof ClientAuthError) {
          // interaction is required
          if (e.errorCode === "user_login_error") {
            this._login();
            return;
          }
        }

        throw e;
      }

      this._loginResponse = loginResponse;
      this._graphResponse = graphResponse;

      if (graphResponse) {
        const res = await fetch(GRAPH_ENDPOINTS.ME, {
          headers: {
            "Authorization": `Bearer ${graphResponse.accessToken}`
          }
        });
        this._user = await res.json();
      } else {
        this._user = msalApp.getAccount();
      }
    },

    _login() {
      window.history.replaceState(null, "", "/");
      return msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN);
    },

    loggedIn() {
      return !!this._loginResponse;
    },

    async logout() {
      if (this._loggingOut) {
        return;
      }

      this._loggingOut = true;

      try {
        await fetch(this.LOGOUT_ENDPOINT, {
          method: "DELETE",
          headers: { "Authorization": `Bearer ${this.getToken()}` }
        });
      } catch (e) {
        console.error('Se produjo un error durante la autenticación:', e);
      }

      msalApp.logout();
    },

    getToken() {
      return this._loginResponse.idToken.rawIdToken;
    },

    getUser() {
      return this._user;
    },

    getUserGivenName() {
      const user = this.getUser();
      if (user instanceof Account) {
        return user.name;
      } else {
        return user?.givenName || "Usuario";
      }
    },

    getClaims() {
      return this._loginResponse.idTokenClaims;
    },

    getRoles() {
      const claims = this.getClaims();

      if (claims.hasOwnProperty("roles")) {
        return claims.roles;
      }
      return null;
    },

    getRoleName() {
      if (this.getRoles()) {
        if (this.getRoles().length > 0) {
          let roleName = this.getRoles()[0].split(":")[0];
          return roleName;
        }
      }
      return null;
    },

    hasAccessToResource(resource, action) {
      if (this.getRoles()) {
        for (var i = 0; i < this.getRoles().length; i++) {
          if (this.getRoles()[i].split(":").length === 3) {
            let resourceName = this.getRoles()[i].split(":")[1];
            let resourceActions = this.getRoles()[i].split(":")[2];
            if (resourceName === resource &&
              resourceActions.includes(action)) {
              return true;
            }
          }
        }
      }
      return false;
    }
  }
}

export default AADService;