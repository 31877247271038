import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import logo from "../../images/Grupo-Bancolombia.png";

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box mt={8}>
            <img src={logo} alt="Logo" width="100%" height="100%" />
          </Box>
        </Container>
      </React.Fragment>
    );
  }
}

export default Home;
