import React from "react";
import Services from "../../services/DocumentTypeService";
import Select from "@material-ui/core/Select";
import { InputLabel } from "@material-ui/core";

class SelectTypeDocument extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [{
        id: 0,
        code: "",
        name: ""
      }],
    };
  }

  async componentDidMount() {
    const response = await Services.documentTypes.getDocumentTypes("");
    if (response.success && response.data != null) {
      this.setState({
        data: this.state.data.concat(Services.documentTypes.getArrayData(response.data)),
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <InputLabel htmlFor="documentType" id="documentTypeLabel">Tipo de documento</InputLabel>
        <Select
          native
          labelId="documentTypeLabel"
          id="documentType"
          name="documentType"
          value={this.props.value}
          onChange={this.props.onChange}
          displayEmpty={true}
          fullWidth
        >
          {this.state.data.map((data) => (
            <option key={data.id} value={data.code}>
              {data.code} {data.name}
            </option>
          ))}
        </Select>
      </React.Fragment>
    );
  }
}
export default SelectTypeDocument;
