import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import Commons from "../../util/Commons";

class SegmentForm extends Component {
  constructor(props) {
    super();
    this.state = {
      form: {
        id: props.data ? props.data.id : null,
        name: props.data ? props.data.name : "",
      },
      errorMessage: "",
      isModalOpen: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        name: e.target.value,
      }
    });
  };

  handleOpenDialog = (e) => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        isModalOpen: true,
        errorMessage: "",
      });
    } else {
      this.setState({
        ...this.state,
        errorMessage: "El nombre es requerido",
      });
    }
  };

  validateForm = () => {
    let response = true;
    if (Commons.validations.hasNotText(this.state.form.name)) {
      response = false;
    }
    return response;
  };

  handleCloseDialog = (e) => {
    this.setState({
      ...this.state,
      isModalOpen: false,
    });
  };

  handleConfirm = (e) => {
    this.props.handleAccept(this.state.form);
  };

  handleKeyPressed = async (e) => {
    if (e.key === "Enter") {
      this.setState({
        isModalOpen: true,
      });
    }
  };

  render() {
    if (this.state.isModalOpen) {
      return (
        <ConfirmDialog
          open={this.state.isModalOpen}
          handleClose={this.handleCloseDialog}
          handleAccept={this.handleConfirm}
          title="¿Desea guardar la agrupación?"
        />
      );
    }

    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.handleClose}>
          <DialogTitle id="form-dialog-title">
            {this.state.form.id ? "Actualizar agrupación" : "Registrar agrupación"}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              autoComplete="off"
              id="name"
              label="Nombre de la agrupación*"
              fullWidth
              value={this.state.form.name}
              onKeyPress={this.handleKeyPressed}
              onChange={this.handleChange}
              inputProps={{ maxLength: 50 }}
            />
            <br />
            <div>
              <p id="errorMessage" style={{ color: "red" }}>
                {this.state.errorMessage}
              </p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" size="small" onClick={this.props.handleClose}>Cancelar</Button>
            <Button variant="contained" size="small" color="primary" onClick={this.handleOpenDialog}>
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(SegmentForm);
