import React from "react";
import Select from "@material-ui/core/Select";
import { InputLabel } from "@material-ui/core";

class SelectProcess extends React.Component {
  constructor() {
    super();
    let process = [
      { id: "", name: "" },
      { id: "CONSULTAR IDENTIDAD", name: "CONSULTA IDENTIDAD" },
      { id: "ACTIVAR IDENTIDAD", name: "ACTIVAR IDENTIDAD" },
      { id: "INACTIVAR IDENTIDAD", name: "INACTIVAR IDENTIDAD" },
      { id: "ACTIVAR ALIAS", name: "ACTIVAR ALIAS" },
      { id: "INACTIVAR ALIAS", name: "INACTIVAR ALIAS" },
      { id: "ELIMINAR ALIAS", name: "ELIMINAR ALIAS" },
      { id: "CONSULTAR ALIAS", name: "CONSULTAR ALIAS" },
      { id: "REGISTRAR AGRUPACION", name: "REGISTRAR AGRUPACION" },
      { id: "ACTUALIZAR AGRUPACION", name: "ACTUALIZAR AGRUPACION" },
      { id: "ELIMINAR AGRUPACION", name: "ELIMINAR AGRUPACION" },
      { id: "REGISTRAR JUSTIFICACION", name: "REGISTRAR JUSTIFICACION" },
      { id: "ACTUALIZAR JUSTIFICACIÓN", name: "ACTUALIZAR JUSTIFICACIÓN" },
      { id: "ELIMINAR JUSTIFICACIÓN", name: "ELIMINAR JUSTIFICACIÓN" },
      { id: "REGISTRAR CONSUMIDOR", name: "REGISTRAR CONSUMIDOR" },
      { id: "ACTUALIZAR CONSUMIDOR", name: "ACTUALIZAR CONSUMIDOR" },
      { id: "CONSULTAR LOG ADMINISTRATIVO", name: "CONSULTAR LOG ADMINISTRATIVO" },
      { id: "CONSULTAR LOG TRANSACCIONAL", name: "CONSULTAR LOG TRANSACCIONAL" },
      { id: "REGISTRAR TIPO DOCUMENTO", name: "REGISTRAR TIPO DOCUMENTO" },
      { id: "ACTUALIZAR TIPO DOCUMENTO", name: "ACTUALIZAR TIPO DOCUMENTO" },
      { id: "MIGRACION BLM", name: "MIGRACION BLM"}
    ];
    process.sort((a, b) => (a.name > b.name ? 1 : -1));
    this.state = {
      data: process
    };
  }

  render() {
    return (
      <React.Fragment>
        <InputLabel htmlFor="process" id="processLabel">Proceso</InputLabel>
        <Select
          native
          labelId="processLabel"
          name="process"
          id="process"
          value={this.props.value}
          onChange={this.props.onChange}
          displayEmpty={true}
          fullWidth
        >
          {this.state.data.map((data) => (
            <option key={data.id} value={data.id}>
              {data.name}
            </option>
          ))}
        </Select>
      </React.Fragment>
    );
  }
}
export default SelectProcess;
