import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function Modal(props) {
  if (!props.isOpen) {
    return null;
  }
  return (
    <div>
      <Dialog open={props.isOpen} onClose={props.onClose}>
        <DialogTitle id="modalTitle">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="modalDescription">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" size="small" onClick={props.onClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
