import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import JustificationService from "../../services/JustificationService";
import MaterialTable from "material-table";
import Modal from "../../components/Modal/Modal";
import { Breadcrumbs, Button, Container, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import Commons from "../../util/Commons";
import MasterJustificationForm from "./JustificationForm";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import AADService from "../../services/AADService";

const useStyles = Commons.styles.getStyles();

class Justification extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      isModalOpen: false,
      modalMessage: null,
      isFormOpen: false,
      isDeleteOpen: false,
      selectedRow: null,
      data: [],
    };
    this.baseState = this.state;
  }

  async componentDidMount() {
    this.setState({ loading: false });
    this.fetchData();
  }

  async fetchData() {
    this.setState({ loading: true });
    const response = await JustificationService.justifications.getJustifications();
    if (response.success && response.data != null) {
      this.setState({
        loading: false,
        data: response.data,
      });
    } else {
      this.setState({ loading: false, data: [] });
    }
  }

  saveData = async (data) => {
    this.setState({ loading: true });
    let response = await JustificationService.justifications.saveJustification(data, this.props.userName);
    this.setState({
      isFormOpen: false,
      isModalOpen: true,
      loading: false,
      modalMessage: response.message,
    });
    this.fetchData();
  };

  deleteData = async () => {
    this.setState({ loading: true });
    let response = await JustificationService.justifications.deleteJustification(this.state.selectedRow, this.props.userName);
    this.setState({
      isDeleteOpen: false,
      isModalOpen: true,
      loading: false,
      modalMessage: response.message,
    });
    this.fetchData();
  };

  handleEditRow = (selectedRow) => {
    this.setState({
      selectedRow: selectedRow,
      isFormOpen: true,
    });
  };

  handleAddRow = () => {
    this.setState({
      isFormOpen: true,
    });
  };

  handleDeleteRow = (selectedRow) => {
    this.setState({
      selectedRow: selectedRow,
      isDeleteOpen: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
      isFormOpen: false,
      modalMessage: "",
      selectedRow: null
    });
  };

  handleCloseForms = () => {
    this.setState(this.baseState);
    this.fetchData();
  };

  render() {
    const { classes } = this.props;

    if (this.state.isModalOpen) {
      return (
        <Modal
          isOpen={this.state.isModalOpen}
          onClose={this.handleCloseModal}
          title="Justificaciones"
          message={this.state.modalMessage}
        />
      );
    }

    if (this.state.isFormOpen) {
      return (
        <MasterJustificationForm
          open={this.state.isFormOpen}
          handleClose={this.handleCloseForms}
          handleAccept={this.saveData}
          data={this.state.selectedRow}
        />
      );
    }

    if (this.state.isDeleteOpen) {
      return (
        <ConfirmDialog
          open={this.state.isDeleteOpen}
          handleClose={this.handleCloseForms}
          handleAccept={this.deleteData}
          title="¿Desea eliminar la justificación?"
        />
      );
    }
    return (
      <React.Fragment>
        <Breadcrumbs className={classes.breadcrumbs}>
          <Typography variant="h5" color="primary">
            SUID
          </Typography>
          <Typography variant="h5" color="textPrimary">
            Justificaciones
          </Typography>
        </Breadcrumbs>
        <div className={classes.paper}>
          {AADService.auth.hasAccessToResource('Justificaciones', 'Crear') &&
            <Button id="btnRegisterJustification" size="small" variant="contained" color="primary"
              onClick={() => { this.handleAddRow(); }} >
              Registrar justificación
            </Button>
          }
          <Container component="main" maxWidth="md">
            <MaterialTable
              title=""
              icons={Commons.materialTable.getIcons()}
              data={this.state.data}
              options={Commons.materialTable.getOptions(true, true, true)}
              localization={Commons.materialTable.getLocalization()}
              columns={[
                {
                  title: "Código",
                  field: "id",
                },
                {
                  title: "Justificación",
                  field: "data",
                  width: 500,
                },
                {
                  title: "Página",
                  field: "page",
                  width: 100,
                },
                {
                  title: "Proceso",
                  field: "process",
                  width: 100,
                },
              ]}
              actions={
                AADService.auth.hasAccessToResource('Justificaciones', 'Modificar') &&
                [{
                  icon: () => <EditIcon />,
                  tooltip: "Editar",
                  onClick: (event, rowData) => {
                    if (rowData) {
                      this.handleEditRow(rowData);
                    }
                  },
                },
                {
                  icon: () => <DeleteIcon />,
                  tooltip: "Eliminar",
                  onClick: (event, rowData) => {
                    if (rowData) {
                      this.handleDeleteRow(rowData);
                    }
                  },
                },
                ]}
            />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(useStyles)(Justification));
