import { UserAgentApplication } from "msal";

export const endpoint = process.env.REACT_APP_REDIRECT_URL;

export const GRAPH_SCOPES = {
  OPENID: "openid",
  PROFILE: "profile",
  USER_READ: "User.Read",
  MAIL_READ: "Mail.Read"
};

export const GRAPH_REQUESTS = {
  GRAPH_LOGIN: {
    scopes: [
      GRAPH_SCOPES.OPENID,
      GRAPH_SCOPES.PROFILE,
      GRAPH_SCOPES.USER_READ
    ]
  },
  LOGIN: {
    scopes: [
      GRAPH_SCOPES.OPENID,
      GRAPH_SCOPES.PROFILE
    ]
  }
};

export const GRAPH_ENDPOINTS = {
  ME: "https://graph.microsoft.com/v1.0/me"
}

export const msalApp = new UserAgentApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
    redirectUri: endpoint,
    validateAuthority: true
  },
  cache: {
    cacheLocation: "sessionStorage"
  }
});
