import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

class ConfirmDialog extends Component {
  render() {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.handleClose}>
          <DialogTitle id="lblDialogTitle">{this.props.title}</DialogTitle>
          <DialogActions>
            <Button size="small" variant="contained" onClick={this.props.handleClose}>Cancelar</Button>
            <Button
              autoFocus
              size="small"
              color="primary"
              variant="contained"
              onClick={() => {
                this.props.handleAccept(this.state);
              }}
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(ConfirmDialog);
