import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import AADService from "../../services/AADService";
import {
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import Commons from "../../util/Commons";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import TextField from "@material-ui/core/TextField";

class ConfirmDeleteContact extends Component {
  constructor() {
    super();
    this.state = {
      justification: "",
      message: "",
      isModalOpen: false,
      isValid: false,
    };
  }

  handleOpenDialog = (e) => {
    if (Commons.validations.hasNotText(this.state.justification.toString())) {
      this.setState({
        ...this.state,
        message: "La justificación es requerida.",
      });
    } else {
      this.setState({
        ...this.state,
        isModalOpen: true,
        message: "",
      });
    }
  };

  handleCloseDialog = (e) => {
    this.setState({
      ...this.state,
      isModalOpen: false,
    });
  };

  handleAccept = (e) => {
    this.props.handleAccept(this.state.justification);
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      justification: e.target.value,
      message: "",
      isValid: true,
    });
  };

  handleChangeOtp = (e) => {
    this.validateJustification(e.target.value);
  };

  validateJustification = (just) => {
    if (just.length === 10 && !isNaN(just) && just.substring(0, 1) === "8") {
      this.setState({
        ...this.state,
        message: "",
        justification: just,
        isValid: true,
      });
    } else if ((just.length === 1 && just !== "8") || just.length !== 10 ||
      isNaN(just)) {
        this.setState({
          ...this.state,
          message: "El radicado no es válido",
          justification: just,
          isValid: false,
        });
    } else {
      this.setState({
        ...this.state,
        message: "El radicado no es válido",
        justification: just,
        isValid: false,
      });
    }
}

  render() {

    if (this.state.isModalOpen) {
      return (
        <ConfirmDialog
          open={this.state.isModalOpen}
          handleClose={this.handleCloseDialog}
          handleAccept={this.handleAccept}
          title={this.props.alertMessage}
        />
      );
    }

    if (AADService.auth.hasAccessToResource('Contactos', 'EliminarJustificacion')) {
      return (
        <div>
          <Dialog open={this.props.open} onClose={this.props.handleClose} fullWidth maxWidth="sm">
            <DialogTitle>{this.props.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{this.props.message}</DialogContentText>
              <TextField
                variant="outlined"
                defaultValue={''}
                value={this.state.justification}
                required
                multiline={true}
                fullWidth
                maxrows={4}
                onChange={this.handleChangeOtp}
                type="number"
                inputProps={{maxLength: 10, minLength: 10}}
              />
              <br />
              <div>
                <p id="errorMessage" style={{ color: "red" }}>
                  {this.state.message}
                </p>
              </div>
            </DialogContent>
            <DialogActions>
              <Button size="small" variant="contained" onClick={this.props.handleClose}>Cancelar</Button>
              <Button size="small" variant="contained" autoFocus color="primary" disabled={!this.state.isValid} onClick={this.handleOpenDialog}>
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    } else {
      return (
        <div>
          <Dialog open={this.props.open} onClose={this.props.handleClose} fullWidth maxWidth="sm">
            <DialogTitle>{this.props.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{this.props.message}</DialogContentText>
              <TextField
                variant="outlined"
                defaultValue={''}
                value={this.state.justification}
                required
                multiline={true}
                fullWidth
                maxrows={4}
                onChange={this.handleChange}
              />
              <br />
              <div>
                <p id="errorMessage" style={{ color: "red" }}>
                  {this.state.message}
                </p>
              </div>
            </DialogContent>
            <DialogActions>
              <Button size="small" variant="contained" onClick={this.props.handleClose}>Cancelar</Button>
              <Button size="small" variant="contained" autoFocus color="primary" disabled={!this.state.isValid} onClick={this.handleOpenDialog}>
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }
}

export default ConfirmDeleteContact;
