import React from "react";
import Grid from "@material-ui/core/Grid";
import HelpIcon from "@material-ui/icons/Help";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  customWidth: {
    maxWidth: 300
  }
}));

export default function InfoTooltip(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Grid container>
        <Grid item>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true
                }}
                onClose={handleTooltipClose}
                placement={props.placement}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                classes={{ tooltip: classes.customWidth }}
                title={props.title}
              >
                <IconButton onClick={handleTooltipOpen}>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Grid>
      </Grid>
    </div>
  );
}
