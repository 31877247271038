import Services from "./Services";

const CONTEXT_GET = "/params/get/";
const CONTEXT_POST = "/params/update";
const CONTEXT_GET_OTP = "/params/otp/get";
const CONTEXT_POST_OTP = "/params/otp/update";
const CONTEXT_POST_DELETE_OTP = "/params/otp/delete";
const CONTEXT_POST_SAVE_OTP = "/params/otp/save";

const services = {
  Params: {
    getParams(nu) {
      var options = {};
      options.method = "GET";
      return Services.rest.callApi(CONTEXT_GET + nu, options);
    },
    getParamsOTP(page = 0, size = 8) {
      var options = {};
      options.method = "GET";
      return Services.rest.callApiCC(CONTEXT_GET_OTP + "?page=" + page + "&size=" + size, options);
    },
    saveParams(data) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify({
        data: [{
          app: data.app,
          id: data.id,
          code: data.code,
          description: data.description,
          value: data.value,
        }]
      });
      let response = Services.rest.callApi(CONTEXT_POST, options);
      return response;
    },
    saveParamOTP(data) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify({
        data: [{
          consumerName: data.consumerName,
          consumerDescription: data.consumerDescription,
          expirationTimeOtp: data.expirationTimeOtp,
          sizeOtp: data.sizeOtp,
          typeOTP: data.typeOTP,
          numberBlocks: data.numberBlocks,
          regenerationOtp: data.regenerationOtp,
          regenerationBlockTime: data.regenerationBlockTime,
          alertaExistente: data.alertaExistente,          
          consumerCopy: data.consumerCopy,        
          consumerCreate: data.consumerCreate
        }],
      });
      let response = Services.rest.callApiCC(CONTEXT_POST_SAVE_OTP, options);
      return response;
    },
    updateParamOTP(data) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify({
        data: [{
          consumerName: data.consumerName,
          consumerDescription: data.consumerDescription,
          expirationTimeOtp: data.expirationTimeOtp,
          sizeOtp: data.sizeOtp,
          typeOTP: data.typeOTP,
          numberBlocks: data.numberBlocks,
          regenerationOtp: data.regenerationOtp,
          regenerationBlockTime: data.regenerationBlockTime,
          alertaExistente: data.alertaExistente,          
          consumerCopy: data.consumerCopy,        
          consumerCreate: data.consumerCreate
        }],
      });
      let response = Services.rest.callApiCC(CONTEXT_POST_OTP, options);
      return response;
    },
    getArrayData({ data }) {
      var params = data.map((param) => {
        return {
          type: param.type,
          id: param.id,
          app: param.attributes.app,
          code: param.attributes.code,
          value: param.attributes.value,
          description: param.attributes.description,
        };
      });
      return params;
    },
    getArrayDataOtp(response) {
      const res = 
      {
        data: response.data,
        page: response.page,
        totalCount: response.total
      };
      return res;
    },
    deleteOtp(justification, id) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify({
        justification: justification,
        consumerId: id
      });
      return Services.rest.callApiCC(CONTEXT_POST_DELETE_OTP, options);
    },
  },
};

export default services;
