import React, { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Commons = {
  validations: {
    hasNotText(str) {
      str = String(str);
      return str === null || str.match(/^ *$/) !== null;
    },
  },
  date: {
    getIsoCurrentDate() {
      let now = new Date().getTime() - 300 * 60 * 1000;
      return new Date(now).toISOString().split(".")[0];
    },
    getIsoStartOfDayDate() {
      let start = new Date();
      start.setHours(0, 0, 0, 0);
      let startColombianTime = start.getTime() - 300 * 60 * 1000
      return new Date(startColombianTime).toISOString().split(".")[0];
    },
    getIsoStartOfDayDateTime() {
   
      let start = new Date();
      start.setDate(start.getDate()-7);
      let startColombianTime = start.getTime() - 300 * 60 * 1000
      return new Date(startColombianTime).toISOString().split(".")[0];
    },
    getIsoStartOfDayDateTimeini() {
      let start = new Date();
      start.setMonth(start.getMonth()-1);
      start.setDate(1);
      let startColombianTime = start.getTime() - 300 * 60 * 1000
      return new Date(startColombianTime).toISOString().split(".")[0];
    },
    getIsoStartOfMonthDate() {
      let start = new Date();
      start.setDate(1);
      start.setHours(0, 0, 0, 0);
      let startColombianTime = start.getTime() - 300 * 60 * 1000
      return new Date(startColombianTime).toISOString().split(".")[0];
    },
    getMinDate() {
      let start = new Date();
      start.setMonth(start.getMonth()-1);
      start.setDate(1);
      start.setHours(0, 0, 0, 0);
      let startColombianTime = start.getTime() - 300 * 60 * 1000
      return new Date(startColombianTime);
    },

    getMinDateMasivo() {
      let start = new Date();
      start.setMonth(start.getMonth()-1);
      start.setDate(1);
      start.setHours(0, 0, 0, 0);
      let startColombianTime = start.getTime() - 300 * 60 * 1000
      return new Date(startColombianTime);
    },
    getIsoStartMinusSixMonths() {
      let start = new Date();
      start.setMonth(start.getMonth()-5);
      start.setDate(1);
      let startColombianTime = start.getTime() - 300 * 60 * 1000
      return new Date(startColombianTime).toISOString().split(".")[0];
    },
    getSixMonthsDepuration(meses){

      if(typeof meses === "undefined" || meses === ""){
        return null;
      } else{
        let start = new Date();
        start.setMonth(start.getMonth()+ parseInt(meses));
        start.setDate(1);
        let startColombianTime = start.getTime() - 300 * 60 * 1000
        return new Date(startColombianTime).toISOString().split(".")[0];
      }
    }
  },
  materialTable: {
    getIcons() {
      return tableIcons;
    },
    getOptions(search = false, toolbar = false, canExport = false, pageSize = 8) {
      return {
        exportButton: {
          csv: canExport,
          pdf: false
        },
        exportAllData: canExport,
        draggable: false,
        pageSize: pageSize,
        pageSizeOptions: [pageSize, 15, 30, 50, 100],
        padding: "dense",
        actionsColumnIndex: -1,
        search: search,
        toolbar: toolbar == null ? true : toolbar,
        cellStyle: {
          fontSize: 10,
        },
        headerStyle: {
          fontWeight: "bold",
          fontSize: "11px",
        }
      };
    },
    getOptionsOtp(search = false, toolbar = false, canExport = false, pageSize = 8) {
      return {
        exportButton: {
          csv: canExport,
          pdf: false
        },
        exportAllData: canExport,
        draggable: false,
        pageSize: pageSize,
        pageSizeOptions: [pageSize, 15, 30, 50, 100],
        padding: "dense",
        actionsColumnIndex: -1,
        search: search,
        toolbar: toolbar == null ? true : toolbar,
        maxBodyHeight: '600px',
        cellStyle: {
          fontSize: 10,
        },
        headerStyle: {
          fontWeight: "bold",
          fontSize: "11px",
          position: 'sticky', top: 0
        }
      };
    },
    getLocalization() {
      return {
        pagination: {
          labelDisplayedRows: "{from}-{to} de {count}",
          labelRowsSelect: "Fila(s)",
          nextTooltip: "Siguiente Página",
          lastTooltip: "Última Página",
          firstTooltip: "Primera Página",
          previousTooltip: "Anterior Página",
        },
        toolbar: {
          nRowsSelected: "{0} fila(s) seleccionada(s)",
          searchTooltip: "Buscar",
          searchPlaceholder: "Buscar",
          exportCSVName: "Exportar como CSV",
          exportTitle: "Exportar",
        },
        header: {
          actions: "",
        },
        body: {
          deleteTooltip: "Eliminar",
          addTooltip: 'Añadir',
          emptyDataSourceMessage: "No se encontraron registros",
          filterRow: {
            filterTooltip: "Filtro",
          },
          editRow: {
            saveTooltip: "Aceptar",
            cancelTooltip: "Cancelar",
            deleteText: "¿Está seguro que desea eliminar este registro?",
          },
        },
      };
    },
  },
  styles: {
    getStyles() {
      const useStyles = (theme) => ({
        "@global": {
          body: {
            backgroundColor: theme.palette.common.white,
          },
        },
        container: {
          marginTop: theme.spacing(1),
          alignItems: "center",
          display: "flex",
        },
        gridList: {
          margin: theme.spacing(1, 3, 1),
          alignItems: "center",
          display: "flex",
        },
        paper: {
          marginTop: theme.spacing(2),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        table: {
          marginTop: theme.spacing(1),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
        formControl: {
          margin: theme.spacing(1, 1, 1),
        },
        breadcrumbs: {
          margin: theme.spacing(1, 3, 1),
        },
        loader: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
          width: "100%",
        },
        textFieldProps: {
          readOnly: true,
          style: { textAlign: "center" },
        },
      });
      return useStyles;
    }
  },
  process: {
    getAliasProcess() {
      return [
        {
          key: "0",
          value: "ACTIVAR ALIAS",
          process: "ACTIVAR ALIAS",

        },
        {
          key: "1",
          value: "INACTIVAR ALIAS",
          process: "INACTIVAR ALIAS"
        },
        {
          key: "2",
          value: "ELIMINAR ALIAS",
          process: "ELIMINAR ALIAS"
        }
      ];
    },
    getIdentityProcess() {
      return [
        {
          key: "0",
          value: "ACTIVAR IDENTIDAD",
          process: "ACTIVAR IDENTIDAD",

        },
        {
          key: "1",
          value: "INACTIVAR IDENTIDAD",
          process: "INACTIVAR IDENTIDAD"
        }
      ];
    }
  }
};
export default Commons;
