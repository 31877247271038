import React from "react";
import {
  Container,
  CssBaseline,
  Grid,
  TextField,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ConfirmChangeStatus from "../../components/ConfirmChangeStatus/ConfirmChangeStatus";
import Commons from "../../util/Commons";
import AADService from "../../services/AADService";
import Services from "../../services/LogsService";
import Modal from "../../components/Modal/Modal";
import ProcessingLogsResults from "../ProcessingLog/ProcessingLogsResults";
import DownloadCsv from "../../components/DownloadCsv/DownloadCsv";
import SelectDocumentType from "../../components/Selects/SelectDocumentType";
import ServicesIndentity from "../../services/IdentityService";


const useStyles = Commons.styles.getStyles();

class IdentityInfo extends React.Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      modalIsOpen: false,
      modalMessage: "",
      resultsIsOpen: false,
      EditIsOpen: true,
      UpdateIsButtom: true,

      form: {
        process: "",
        document: "",
        result: "",
        date: Commons.date.getIsoStartMinusSixMonths(),
        primerNombre: props.data.firstName,
        segundoNombre: props.data.secondName,
        primerApellido: props.data.firstSurname,
        segundoApellido: props.data.secondSurname,
        documento: props.data.document,
        documentType: props.data.documentType
      },

      changeStatus: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [e.target.id]: e.target.value,
      }
    });
  };

  handleUpdateIdentity = async (e) => {
    this.setState({ loading: true });
    const response = await ServicesIndentity.identities.updateIdentity(
      {
        cid: this.props.data.id,
        primerNombre: this.state.form.primerNombre,
        segundoNombre: this.state.form.segundoNombre,
        primerApellido: this.state.form.primerApellido,
        segundoApellido: this.state.form.segundoApellido,
        numeroDocumento: this.state.form.documento,
        tipoDocumento: this.state.form.documentType,
      },
      this.props.userName
    );
    if (response.success && response.data != null) {
      this.setState({
        loading: false,
        EditIsOpen: true,
        UpdateIsButtom: true
      });
    } else {
      this.setState({
        loading: false,
        modalIsOpen: true,
        modalMessage: response.message,
      });
    }
  };

  handleUpdateStatus = (e) =>{
    this.setState({ EditIsOpen: false, UpdateIsButtom: false })
  };

  handleOpenChangeStatus = (e) => {
    this.setState({ changeStatus: true });
  };

  handleCloseChangeStatus = (e) => {
    this.setState({ changeStatus: false });
  };

  handleCloseModal = (e) => {
    this.setState({ modalIsOpen: false });
  };


  onClickSearch = async (e) => {
    const { documentType, document } = this.props.form;
    this.setState({
      loading: true,
      form: Object.assign({}, this.state.form, { documentType, document })
    });

    const response = await Services.logs.getProcessingLog(
      this.state.form,
      this.props.userName
    );
    
    const items = response.data;

    items.sort(((a, b) => a.date.substr(0,2) - b.date.substr(0,2)));
    items.reverse();
    items.length = 7;
    items.reverse();

    if (response.data != null) {

      this.setState({
        loading: false,
        resultsIsOpen: true,
        data: items,
      });
    } else {
      this.setState({
        loading: false,
        resultsIsOpen: false,
        data: null,
        modalIsOpen: true,
        modalMessage: response.message,
      });
    }
  };

  handleCloseResults = (e) => {
    this.setState({ resultsIsOpen: false });
  };


  render() {
    const { classes } = this.props;

    if (this.state.loading === true) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }

    if (this.state.modalIsOpen) {
      return (
        <Modal
          isOpen={this.state.modalIsOpen}
          onClose={this.handleCloseModal}
          title="Log De procesamiento"
          message={this.state.modalMessage}
        />
      );
    }

    if (this.state.changeStatus) {
      return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <ConfirmChangeStatus
            open={this.state.changeStatus}
            handleClose={this.handleCloseChangeStatus}
            handleAccept={this.props.onChangeStatus}
            page={"IDENTIDAD"}
            process={this.props.data.status === "ACTIVO" ? "INACTIVAR IDENTIDAD" : "ACTIVAR IDENTIDAD"}
            message={this.props.data.status === "ACTIVO" ?
              "Seleccione la justificación para inactivar la identidad" :
              "Seleccione la justificación para activar la identidad"}
            alertMessage={this.props.data.status === "ACTIVO" ?
              `Al inactivar la identidad se bloquea el ingreso de ${this.props.data.name} en todos los canales Banco. ¿Desea continuar?` :
              `Al activar la identidad se permite el ingreso de ${this.props.data.name} en todos los canales Banco. ¿Desea continuar?`}
          ></ConfirmChangeStatus>
        </Container>
      );
    }
    return (
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <Grid container spacing={1}>
          <Grid item xs={2}>
              <TextField
                inputProps={{
                  readOnly: this.state.EditIsOpen,
                  style: { textAlign: "center" },
                }}
                size="small"
                fullWidth
                id="primerNombre"
                label="Primer Nombre"
                onChange={this.handleChange}
                value={this.state.form.primerNombre}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                inputProps={{
                  readOnly: this.state.EditIsOpen,
                  style: { textAlign: "center" },
                }}
                size="small"
                fullWidth
                id="segundoNombre"
                label="Segundo Nombre"
                onChange={this.handleChange}
                value={this.state.form.segundoNombre}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                inputProps={{
                  readOnly: this.state.EditIsOpen,
                  style: { textAlign: "center" },
                }}
                size="small"
                fullWidth
                id="primerApellido"
                label="Primer Apellido"
                onChange={this.handleChange}
                value={this.state.form.primerApellido}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                inputProps={{
                  readOnly: this.state.EditIsOpen,
                  style: { textAlign: "center" },
                }}
                size="small"
                fullWidth
                id="segundoApellido"
                label="Segundo Apellido"
                onChange={this.handleChange}
                value={this.state.form.segundoApellido}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectDocumentType
                value={this.state.form.documentType}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={2} className={classes.formControl}>
              <TextField
                inputProps={{
                  readOnly: this.state.EditIsOpen,
                  style: { textAlign: "center" },
                }}
                size="small"
                type="number"
                fullWidth
                id="documento"
                label="Documento"
                onChange={this.handleChange}
                value={this.state.form.documento}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                }}
                size="small"
                fullWidth
                id="cid"
                label="CID"
                value={this.props.data.id + " "}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                }}
                size="small"
                fullWidth
                id="status"
                label="Estado"
                value={this.props.data.status + " "}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                }}
                size="small"
                fullWidth
                id="type"
                label="Tipo de identidad"
                value={this.props.data.type + " "}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                }}
                size="small"
                fullWidth
                id="mdmCode"
                label="Código MDM"
                value={this.props.data.mdmCode + " "}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                }}
                size="small"
                fullWidth
                id="consumerCreate"
                label="Consumidor de registro"
                value={this.props.data.consumerCreate + " "}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                }}
                size="small"
                fullWidth
                id="dateCreate"
                label="Fecha de registro"
                value={this.props.data.dateCreate + " "}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                }}
                size="small"
                fullWidth
                id="timeCreate"
                label="Hora de registro"
                value={this.props.data.timeCreate + " "}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                }}
                size="small"
                fullWidth
                id="consumerUpdate"
                label="Consumidor última actualización"
                value={this.props.data.consumerUpdate + " "}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                }}
                size="small"
                fullWidth
                id="dateUpdate"
                label="Fecha de actualización"
                value={this.props.data.dateUpdate + " "}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                }}
                size="small"
                fullWidth
                id="timeUpdate"
                label="Hora de actualización"
                value={this.props.data.timeUpdate + " "}
              />
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-end">
              
              <Grid item>
                <Button
                  id="btnClearIdentityInfo"
                  variant="contained"
                  size="small"
                  onClick={this.props.onReset}>
                  Limpiar
                </Button>
              </Grid>
              {AADService.auth.hasAccessToResource("Identidad", "CambioEstado") &&
                <Grid item>
                  <Button
                    id="btnChangeStatusIdentity"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={this.handleOpenChangeStatus}
                  >
                    Cambiar Estado
                  </Button>
                </Grid>
              } 
              {this.state.UpdateIsButtom && AADService.auth.hasAccessToResource("Identidad", "CambioEstado") &&
                <Grid item>
                  <Button
                    id="btnUpdateIdentity"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={this.handleUpdateStatus}
                  >
                    Editar
                  </Button>
                </Grid>
              }
              {!this.state.UpdateIsButtom &&
                <Grid item>
                  <Button
                    id="btnUpdateGoIdentity"
                    variant="contained"
                    color="inherit"
                    size="small"
                    onClick={this.handleUpdateIdentity}
                  >
                    Enviar
                  </Button>
                </Grid>
              }
            </Grid>
          </Grid>
        </div>
        <div className={classes.table}>
          {this.state.resultsIsOpen &&
            this.state.data.length <= 1000 &&
            ProcessingLogsResults(
              this.state.resultsIsOpen,
              this.state.data,
              this.handleCloseResults
            )}
        </div>
        <div className={classes.table}>
          {this.state.resultsIsOpen &&
            this.state.data.length > 1000 &&
            <DownloadCsv
              title="Resultados Log De Procesamiento"
              open={this.state.resultsIsOpen}
              handleClose={this.handleCloseResults}
              data={this.state.data}
              headers={Services.logs.getProcessingLogHeaders()}
            />}
        </div>
      </Container>
    );
  }
}

export default withRouter(withStyles(useStyles)(IdentityInfo));
