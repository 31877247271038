import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import Commons from "../../util/Commons";
import { Container, Grid, InputLabel, Select } from "@material-ui/core";

class ParamsOtpForm extends React.Component {
	constructor(props) {
		super();
		this.state = {
			form: {
				consumerId: props.data.consumerId,
				expirationTimeOtp: props.data.expirationTimeOtp,
				sizeOtp: props.data.sizeOtp,
				numberBlocks: props.data.numberBlocks,
				regenerationOtp: props.data.regenerationOtp,
				regenerationBlockTime: props.data.regenerationBlockTime,
				alertaExistente: props.data.alertaExistente,
				consumerName: props.data.consumerName,
				consumerDescription: props.data.consumerDescription,
				consumerCopy: props.data.consumerCopy,
				typeOTP: props.data.typeOTP,
				consumerCreate: props.data.consumerCreate,
				consumerUpdate: props.data.consumerUpdate
			},
			errorMessage: "",
			isModalOpen: false,
		};
	}

	handleChange = (e) => {
		this.setState({
			...this.state,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value,
			}
		});
	};

	handleOpenDialog = (e) => {
		let validationMessage = this.validateForm();
		if (Commons.validations.hasNotText(validationMessage)) {
			this.setState({
				...this.state,
				isModalOpen: true,
				errorMessage: "",
			});
		} else {
			this.setState({
				...this.state,
				errorMessage: validationMessage,
			});
		}
	};

	validateForm = () => {
		var errors = "";
		if (Commons.validations.hasNotText(this.state.form.typeOTP)) {
			errors += "Tipo de OTP";			
		}  
		if (Commons.validations.hasNotText(this.state.form.expirationTimeOtp)) {
			errors += errors === "" ? "Vigencia" : ", Vigencia";
		}
		if (Commons.validations.hasNotText(this.state.form.sizeOtp)) {
			errors += errors === "" ? "Longitud" : ", Longitud";
		}
		if (Commons.validations.hasNotText(this.state.form.numberBlocks)) {
			errors += errors === "" ? "Número de intentos" : ", Número de intentos";
		}
		if (Commons.validations.hasNotText(this.state.form.regenerationOtp)) {
			errors += errors === "" ? "Número de intentos regeneración" : ", Número de intentos regeneración";
		}
		if (Commons.validations.hasNotText(this.state.form.regenerationBlockTime)) {
			errors += errors === "" ? "Tiempo de bloqueo de regeneración" : ", Tiempo de bloqueo de regeneración";
		}
		if (errors !== "") {
			errors = "Los siguientes datos son requeridos: " + errors;
		}
		return errors;
	};

	handleCloseDialog = (e) => {
		this.setState({
			...this.state,
			isModalOpen: false,
		});
	};

	handleConfirm = (e) => {
		this.props.handleAccept(this.state.form);
	};


	handleKeyPressed = async (e) => {
		if (e.key === "Enter") {
			this.setState({
				isModalOpen: true,
			});
		}
	};

	handleChangeAlerta = (e) => {
		if(e.target.value.match("^[0-9]*$") != null) {
			this.setState({
				...this.state,
				form: {
					...this.state.form,
					[e.target.name]: e.target.value,
				}
			});
		} else {
			this.setState({
				...this.state,
				form: {
					...this.state.form,
					[e.target.name]: "",
				}
			});
		}
	 } 

	render() {
		if (this.state.isModalOpen) {
			return (
				<ConfirmDialog
					open={this.state.isModalOpen}
					handleClose={this.handleCloseDialog}
					handleAccept={this.handleConfirm}
					title="¿Desea guardar el parametro?"
				/>
			);
		}

		return (
			<div>
				<Dialog fullWidth={true} maxWidth="md"
				open={this.props.open} onClose={this.props.handleClose}>
					<DialogTitle id="form-dialog-title">
						{this.state.form.consumerId ? "Actualizar parametro" : "Registrar parametro"}
					</DialogTitle>
					<DialogContent>
						<Container maxWidth="md">
							<Grid container spacing={1} direction="column">
								<Grid item>
									<TextField
										name="consumerName"
										label="Canal"
										value={this.state.form.consumerName}
										onKeyPress={this.handleKeyPressed}
										onChange={this.handleChange}
										inputProps={{ maxLength: 100 }}
										disabled="true"
									/>
								</Grid>
								<Grid item>
									<InputLabel id="typeOTP">Tipo de OTP</InputLabel>
										<Select
											native
											labelId="typeOTP"
											name="typeOTP"
											margin="dense"
											value={this.state.form.typeOTP}
											onChange={this.handleChange}
											displayEmpty={false}
										>
										<option key="NUMERIC" value="NUMERIC">
											Numérico
										</option>
										<option key="ALPHANUMERIC" value="ALPHANUMERIC">
											Alfanumérico
										</option>
									</Select>
								</Grid>
								<Grid item>
									<TextField
										name="expirationTimeOtp"
										label="Vigencia (min)"
										value={this.state.form.expirationTimeOtp}
										onKeyPress={this.handleKeyPressed}
										onChange={this.handleChange}
										inputProps={{ min: 0, maxLength: 2 }}
										type="number"
									/>
								</Grid>
								<Grid item>
									<TextField
										name="sizeOtp"
										label="Longitud"
										value={this.state.form.sizeOtp}
										onKeyPress={this.handleKeyPressed}
										onChange={this.handleChange}
										inputProps={{ min: 0, maxLength: 2 }}
										type="number"
									/>
								</Grid>
								<Grid item>
									<TextField
										name="numberBlocks"
										label="Número de intentos fallidos"
										value={this.state.form.numberBlocks}
										onKeyPress={this.handleKeyPressed}
										onChange={this.handleChange}
										inputProps={{ min: 0, maxLength: 2 }}
										type="number"
									/>
								</Grid>
								<Grid item>
									<TextField
										name="regenerationOtp"
										label="Número de intentos regeneración"
										value={this.state.form.regenerationOtp}
										onKeyPress={this.handleKeyPressed}
										onChange={this.handleChange}
										inputProps={{ min: 0, maxLength: 2 }}
										type="number"
									/>
								</Grid>
								<Grid item>
									<TextField
										name="regenerationBlockTime"
										label="Tiempo de bloqueo regeneración (horas)"
										value={this.state.form.regenerationBlockTime}
										onKeyPress={this.handleKeyPressed}
										onChange={this.handleChange}
										inputProps={{ min: 0, maxLength: 2 }}
										type="number"
									/>
								</Grid>
								<Grid item>
									<TextField
										name="consumerCopy"
										label="Copy"
										fullWidth
										value={this.state.form.consumerCopy}
										onKeyPress={this.handleKeyPressed}
										onChange={this.handleChange}
										inputProps={{ maxLength: 300 }}
									/>
								</Grid>
								<Grid item>
									<TextField
										name="alertaExistente"
										label="Alerta"
										value={this.state.form.alertaExistente}
										onKeyPress={this.handleKeyPressed}
										onChange={this.handleChangeAlerta}
										inputProps={{ maxLength: 8 }}
									/>
								</Grid>
							</Grid>
						</Container>
						<br />
						<div>
							<p id="errorMessage" style={{ color: "red" }}>
								{this.state.errorMessage}
							</p>
						</div>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" size="small" onClick={this.props.handleClose}>Cancelar</Button>
						<Button variant="contained" size="small" color="primary" onClick={this.handleOpenDialog}>
							Guardar
            </Button>
					</DialogActions>
				</Dialog>
			</div >
		);
	}
}

export default withRouter((ParamsOtpForm));
