import { withStyles } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router";
import ParamsService from "../../services/ParamsService";
import Modal from "../../components/Modal/Modal";
import MaterialTable from "material-table";
import { Breadcrumbs,  Container, Typography, Button, Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Commons from "../../util/Commons";
import ParamsForm from "./paramsForm";
import ParamsOtpForm from "./paramsOtpForm";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import ConfirmDeleteParam from "../../components/ConfirmDeleteParam/ConfirmDeleteParam";
import AADService from "../../services/AADService";


const useStyles = Commons.styles.getStyles();
const SERVER = process.env.REACT_APP_API_DOMAIN;
const CONTEXT_GET_OTP = "/params/otp/get";

class Params extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      isModalOpen: false,
      modalMessage: null,
      isFormOpen: false,
      isEditOpen: false,
      selectedRow: null,
      createModalOpen: false,
      isConfirmOpen: false,
      isDeleteOpen: false,
      alertMessage: '¿Está seguro que desea registrar un nuevo consumidor?',
      data: [],
      rowData: null,
      nu : "NU0209001",
      tableRef: React.createRef(),
      columns: [
        { title: "Código", field: "code" },
        { title: "Valor", field: "value" },
      ],
      columnsOtp: [
        {
          title: "Consumer ID",
          field: "consumerId",
          render: (rowData) => {
            return (
              <p>
                {rowData.consumerId}
              </p>
            );
          },
          hidden: true,
          searchable: false,
        },
        { title: "Fecha creación", field: "consumerCreate",
          render: (rowData) => {
            return (
              <p>
                {rowData.consumerCreate}
              </p>
            );
          },
          editable: "never"
        },
        { title: "Fecha última actualización", field: "consumerUpdate",
          render: (rowData) => {
            return (
              <p>
                {rowData.consumerUpdate}
              </p>
            );
          },
          editable: "never"
        },
        { title: "Acrónimo", field: "consumerName",
          render: (rowData) => {
            return (
              <p>
                {rowData.consumerName}
              </p>
            );
          },
          searchable: true,
          validate: (rowData) =>
              rowData.consumerName === undefined
                ? { isValid: false, helperText: "Requerido *" }
                : rowData.consumerName === ""
                ? { isValid: false, helperText: "Requerido *" }
                : true,
        },
        { title: "Nombre consumidor", field: "consumerDescription",
          render: (rowData) => {
            return (
              <p>
                {rowData.consumerDescription}
              </p>
            );
          },
          searchable: true,
          validate: (rowData) =>
              rowData.consumerDescription === undefined
                ? { isValid: false, helperText: "Requerido *" }
                : rowData.consumerDescription === ""
                ? { isValid: false, helperText: "Requerido *" }
                : true
        },
        { title: "Tipo de OTP", field: "typeOTP",
          lookup: { NUMERIC: "Numérico", ALPHANUMERIC: "Alfanumérico" },
          searchable: false,
          render: (rowData) => {
            return (
              <p>
                {rowData.typeOTP}
              </p>
            );
          },
          validate: (rowData) =>
              rowData.typeOTP === undefined
                ? { isValid: false, helperText: "Requerido *" }
                : rowData.typeOTP === ""
                ? { isValid: false, helperText: "Requerido *" }
                : true
        },
        { title: "Vigencia (min)", field: "expirationTimeOtp",
          type: "numeric",
          searchable: false,
          render: (rowData) => {
            return (
              <p>
                {rowData.expirationTimeOtp}
              </p>
            );
          },
          validate: (rowData) =>
              rowData.expirationTimeOtp === undefined
                ? { isValid: false, helperText: "Requerido *" }
                : rowData.expirationTimeOtp === ""
                ? { isValid: false, helperText: "Requerido *" }
                : rowData.expirationTimeOtp <= 0
                ? { isValid: false, helperText: "Debe ser mayor a 0"}
                : true
        },
        { title: "Longitud", field: "sizeOtp",
          type: "numeric",
          searchable: false,
          render: (rowData) => {
            return (
              <p>
                {rowData.sizeOtp}
              </p>
            );
          },
          validate: (rowData) =>
              rowData.sizeOtp === undefined
                ? { isValid: false, helperText: "Requerido *" }
                : rowData.sizeOtp === ""
                ? { isValid: false, helperText: "Requerido *" }
                : rowData.sizeOtp <= 0
                ? { isValid: false, helperText: "Debe ser mayor a 0"}
                : true
        },
        { title: "Intentos fallidos", field: "numberBlocks",
          type: "numeric",
          searchable: false,
          render: (rowData) => {
            return (
              <p>
                {rowData.numberBlocks}
              </p>
            );
          },
          validate: (rowData) =>
              rowData.numberBlocks === undefined
                ? { isValid: false, helperText: "Requerido *" }
                : rowData.numberBlocks === ""
                ? { isValid: false, helperText: "Requerido *" }
                : rowData.numberBlocks <= 0
                ? { isValid: false, helperText: "Debe ser mayor a 0"}
                : true
        },
        { title: "Intentos regeneración", field: "regenerationOtp",
          type: "numeric",
          searchable: false,
          render: (rowData) => {
            return (
              <p>
                {rowData.regenerationOtp}
              </p>
            );
          },
          validate: (rowData) =>
              rowData.regenerationOtp === undefined
                ? { isValid: false, helperText: "Requerido *" }
                : rowData.regenerationOtp === ""
                ? { isValid: false, helperText: "Requerido *" }
                : rowData.regenerationOtp < 0
                ? { isValid: false, helperText: "Debe ser mayor o igual a 0"}
                : true
        },
        { title: "Tiempo de bloqueo regeneración (hr)", field: "regenerationBlockTime",
          type: "numeric",
          searchable: false,
          render: (rowData) => {
            return (
              <p>
                {rowData.regenerationBlockTime}
              </p>
            );
          },
          validate: (rowData) =>
              rowData.regenerationBlockTime === undefined
                ? { isValid: false, helperText: "Requerido *" }
                : rowData.regenerationBlockTime === ""
                ? { isValid: false, helperText: "Requerido *" }
                : rowData.regenerationBlockTime <= 0
                ? { isValid: false, helperText: "Debe ser mayor a 0"}
                : true
        },
        { title: "Copy", field: "consumerCopy",
          render: (rowData) => {
            return (
              <p>
                {rowData.consumerCopy}
              </p>
            );
          }
        },
        { title: "Alerta", field: "alertaExistente",
          render: (rowData) => {
            return (
              <p>
                {rowData.alertaExistente}
              </p>
            );
          }
        },
      ]
    };
    this.baseState = this.state;
  }

  handleCloseDialog = (e) => {
    this.setState({
      ...this.state,
      isConfirmOpen: false,
    });
  };

  handleCloseDelete = (e, reason) => {
    if (reason && reason === "backdropClick")
      return;
    this.setState({ isDeleteOpen: false });
  };

  async componentDidMount() {
    this.setState({ loading: false });
    if (this.state.nu !== 'NU0209001') {
      this.fetchData(this.state.nu);
    }
  }

  async fetchData(nu) {
    this.setState({ loading: true });
    const response = await ParamsService.Params.getParams(nu);
    if (response.data != null) {
      this.setState({
        loading: false,
        data: ParamsService.Params.getArrayData(response),
      });
    } else {
      this.setState({ loading: false, data: [] });
    }
  }

  async fetchDataOTP(page = 0, size = 1000) {
    this.setState({ loading: true });
    const response = await ParamsService.Params.getParamsOTP(page, size);
    if (response.data != null) {
      this.setState({
        loading: false,
        data: ParamsService.Params.getArrayDataOtp(response),
      });
    } else {
      this.setState({ loading: false, data: [] });
    }
  }

  saveData = async (data) => {
    this.setState({ loading: true });
    const response = await ParamsService.Params.saveParams(data);
    this.setState({
      isFormOpen: false,
      isModalOpen: true,
      loading: false,
      modalMessage: response.message,
    });
    this.fetchData(this.state.nu); 
  };

  saveDataOtp = async (data) => {
    this.setState({ loading: true });
    const response = await ParamsService.Params.updateParamOTP(data);
    this.setState({
      ...this.state,
      isEditOpen: false,
      isModalOpen: true,
      loading: false,
      modalMessage: response.message,
    });
    this.handleChangeParamsOtp('NU0209001');
  };

  handleEditRow = (selectedRow) => {
    this.setState({
      selectedRow: selectedRow,
      isFormOpen: this.state.nu === 'NU0209001' ? false : true,
      isEditOpen: this.state.nu === 'NU0209001' ? true : false,
    });
  };
  
  handleDeleteRow = (selectedRow) => {
    this.setState({
      rowData: selectedRow,
      isDeleteOpen: true
    });
  };


  handleSaveParam = (newRow) => {
    this.setState({
      ...this.state,
      rowData: newRow,
      isConfirmOpen: true,
    });
  };
  
  handleDeleteParam = async (justification) => {
    this.setState({ 
      ...this.state,
      loading: true
    });
    const response = await ParamsService.Params.deleteOtp(
      justification, this.state.rowData.consumerId
    );
    this.setState({
      isDeleteOpen: false,
      isModalOpen: true,
      loading: false,
      modalMessage: response.message,
    });
  };

  handleAcceptSave = async () => {
    this.setState({ loading: true, isConfirmOpen: false });
    const response = await ParamsService.Params.saveParamOTP(this.state.rowData);
    this.setState({
      isFormOpen: false,
      isModalOpen: true,
      loading: false,
      modalMessage: response.message,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
      isFormOpen: false,
      modalMessage: "",
      selectedRow: null,
    });
  };

  handleCloseForms = (e, reason) => {
    if (reason && reason === "backdropClick") {
        return;
    } else {
      this.setState(this.baseState);
    }
    if (this.state.nu !== 'NU0209001') {
      this.handleChangeParams(this.state.nu);
    }
  };

  async handleChangeParams (nu) {
    await this.setState({nu});
    this.fetchData(this.state.nu);
  }

  async handleChangeParamsOtp (nu) {
    await this.setState({
      ...this.state,
      nu
    });
    if (this.state.tableRef.current !== null) {
      this.state.tableRef.current.onQueryChange();
    }
  }

  render() {
    const { classes } = this.props;

    const dataTable = (query) => (
      new Promise((resolve, reject) => {
        let url = SERVER + CONTEXT_GET_OTP + "?";
        url += "page=" + (query.page);
        url += "&size=" + query.pageSize;        
        fetch(url, { method: 'get', headers: new Headers({ 
          "Content-Type": "application/json",
          Authorization: "Bearer " + AADService.auth.getToken(),
        })})
        .then(response => response.json())
        .then(result => {
          resolve({
            data: result.success === true ? result.data : [],
            page: result.success === true ? result.page : 0,
            totalCount: result.success === true ? result.total : 0,
          });
        });
    }));

    if (this.state.isModalOpen) {
      return (
        <Modal
          isOpen={this.state.isModalOpen}
          onClose={this.handleCloseModal}
          title="Parametros"
          message={this.state.modalMessage}
        />
      );
    }

    if (this.state.isConfirmOpen) {
      return (
        <ConfirmDialog
          open={this.state.isConfirmOpen}
          handleClose={this.handleCloseDialog}
          handleAccept={this.handleAcceptSave}
          title={this.state.alertMessage}
        />
      );
    }

    if (this.state.isFormOpen) {
      return (
        <ParamsForm
          open={this.state.isFormOpen}
          handleClose={this.handleCloseForms}
          handleAccept={this.saveData}
          data={this.state.selectedRow}
        />
      );
    }

    if (this.state.isEditOpen) {
      return (
        <ParamsOtpForm
          open={this.state.isEditOpen}
          handleClose={this.handleCloseForms}
          handleAccept={this.saveDataOtp}
          data={this.state.selectedRow}
        />
      );
    }

    if (this.state.isDeleteOpen) {
      return (
        <ConfirmDeleteParam
            open={this.state.isDeleteOpen}
            handleClose={this.handleCloseDelete}
            handleAccept={this.handleDeleteParam}
            page={"Parametros"}
            process={"ELIMINAR PARÁMETRO"}
            message="Ingresa la justificación para eliminar el parámetro"
            alertMessage={`¿Está seguro que desea continuar?`}
          ></ConfirmDeleteParam>
      );
    }

    if (this.state.createModalOpen) {
      return (
        <ParamsForm
          open={this.state.isFormOpen}
          handleClose={this.handleCloseForms}
          handleAccept={this.saveData}
          data={this.state.selectedRow}
        />
      );
    }

    return (
      <React.Fragment>
        <Breadcrumbs className={classes.breadcrumbs}>
          <Typography variant="h5" color="primary">
            SUID
          </Typography>
          <Typography variant="h5" color="textPrimary">
            Parametros
          </Typography>
        </Breadcrumbs>
        <div className={classes.paper}>
          <Grid container spacing={2} justify="center">
            <Grid item xs={2}> <Button id="btnChangeParams" size="small" variant="contained"
              color={this.state.nu === "NU0106001" ? "primary" : "default"}
              onClick={() => { this.handleChangeParams("NU0106001"); }} >
              Contactos de confianza
            </Button>
            </Grid>
            <Grid item xs={2}>
            <Button id="btnChangeParamsCD" size="small" variant="contained" 
              color={this.state.nu === "NU0100001" ? "primary" : "default"}
              onClick={() => { this.handleChangeParams("NU0100001"); }} >
              Desbloqueo con tarjeta
            </Button>
            </Grid>
            <Grid item xs={2}> <Button id="btnOtpParams" size="small" variant="contained"
              color={this.state.nu === "NU0209001" ? "primary" : "default"}
              onClick={() => { this.handleChangeParamsOtp("NU0209001"); }} >
              OTP Genérico
            </Button>
            </Grid>
          </Grid>
          <Container component="main" maxWidth={this.state.nu === "NU0209001" ? "lg" : "md"}>
            <MaterialTable
              title=""
              icons={Commons.materialTable.getIcons()}
              data={this.state.nu === 'NU0209001' ? dataTable : this.state.data}
              options={
                this.state.nu === 'NU0209001' ? Commons.materialTable.getOptionsOtp(false, true, true) :
                Commons.materialTable.getOptions(true, true, true)              
              }
              localization={Commons.materialTable.getLocalization()}
              tableRef={this.state.tableRef}
              columns={this.state.nu === "NU0209001" ? this.state.columnsOtp : this.state.columns}
              actions={[
                  {
                    icon: () => <EditIcon />,
                    tooltip: "Editar",
                    onClick: (event, rowData) => {
                      if (rowData) {
                        this.handleEditRow(rowData);
                      }
                    },
                    hidden: !AADService.auth.hasAccessToResource('Params', 'ModificarParams')
                  },
                  {
                    icon: () => <DeleteIcon />,
                    tooltip: "Eliminar",
                    onClick: (event, rowData) => {
                      if (rowData) {
                        this.handleDeleteRow(rowData);
                      }
                    },
                    hidden: !AADService.auth.hasAccessToResource('Params', 'EliminarParams') || this.state.nu !== "NU0209001"
                  },
                ]}
              editable={this.state.nu === "NU0209001" ? 
              {
                onRowAdd: AADService.auth.hasAccessToResource('Params', 'CrearParams') ? (newData) => 
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      this.handleSaveParam(newData);      
                      resolve();
                    }, 4);
                  }) : undefined,
              } : []}
            />
          </Container>
        </div>
        </React.Fragment>
    );
  }
}

export default withRouter(withStyles(useStyles)(Params));
