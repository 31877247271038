import React from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  InputLabel,
  Select,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Commons from "../../util/Commons";
import Modal from "../../components/Modal/Modal";
import Services from "../../services/ContactService";
import ContactsResults from "./ContactsResults";
import DownloadCsv from "../../components/DownloadCsv/DownloadCsv";
import AADService from "../../services/AADService";

const useStyles = Commons.styles.getStyles();

class ContactForm extends React.Component {
  constructor(props) {
    super();
    this.state = {
      form: {
        channel: "",
        startDate: Commons.date.getIsoStartOfDayDate(),
        endDate: Commons.date.getIsoCurrentDate(),
        alias: "",
        status: "",
        cellNumber: "",
        email: "",
      },
      modalIsOpen: false,
      modalMessage: "",
      loading: true,
      resultsIsOpen: false,
    };
    this.baseState = this.state;
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  handleChange = (e) => {   
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeChannel = (e) => {   
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value.toUpperCase(),
      },
    });
  };

  resetForm = () => {
    this.setState(this.baseState);
    this.setState({ loading: false });
  };

  validateForm = () => {
    var errors = "";
    if (Commons.validations.hasNotText(this.state.form.startDate) || Commons.validations.hasNotText(this.state.form.endDate)
      || Commons.validations.hasNotText(this.state.form.channel)) {
      errors = "Faltan datos requeridos *";
    } else if(this.state.form.startDate < Commons.date.getMinDate()){
      errors = "La fecha de inicio debe ser mayor a " + Commons.date.getMinDate().toLocaleString();
    }    
    return errors;
  };

  onClickSearch = async (e) => {
    let errors = this.validateForm();
    if(!Commons.validations.hasNotText(errors)){
      this.setState({
        ...this.state,
        modalIsOpen: true,
        modalMessage: errors,
      });
    } else {
      this.setState({ loading: true });
      const response = await Services.contact.getContactsAll(
        this.state.form,
        this.props.userName
      );
      if (response.data != null) {
        this.setState({
          loading: false,
          resultsIsOpen: true,
          data: response.data,
        });
      } else {
        this.setState({
          loading: false,
          resultsIsOpen: false,
          data: null,
          modalIsOpen: true,
          modalMessage: response.message,
        });
      }
    }   
  };

  handleCloseDialog = (e) => {
    this.setState({
      modalIsOpen: false,
    });
  };

  handleConfirm = (e) => {
    this.props.handleAccept(this.state.form);
  };

  handleKeyPressed = async (e) => {
    if (e.key === "Enter") {
      this.setState({
        modalIsOpen: true,
      });
    }
  };

  handleCloseResults = (e) => {
    this.setState({ resultsIsOpen: false });
  };

  render() {
    const { classes } = this.props;

    if (this.state.loading === true) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }

    if (this.state.modalIsOpen) {
      return (
        <Modal
          isOpen={this.state.modalIsOpen}
          onClose={this.handleCloseDialog}
          title="Búsqueda Contactos"
          message={this.state.modalMessage}
        />
      );
    }

    return (
      <React.Fragment>
        <Container component="main" maxWidth="md">
          <Grid container className={classes.container} spacing={1}>
            <Grid item xs={2} className={classes.formControl}>
              <InputLabel id="statusLabel">Estado</InputLabel>
              <Select
                native
                labelId="statusLabel"
                name="status"
                margin="dense"
                fullWidth
                value={this.state.form.status}
                onChange={this.handleChange}
                displayEmpty
              >
                <option key="" value="" />
                <option key="ACTIVO" value="ACTIVO">
                  ACTIVO
                </option>
                <option key="BLOQUEADO POR SEGURIDAD" value="BLOQUEADO POR SEGURIDAD">
                  BLOQUEADO POR SEGURIDAD
                </option>
                <option key="BLOQUEADO POR INTENTOS" value="BLOQUEADO POR INTENTOS">
                  BLOQUEADO POR INTENTOS
                </option>
                <option key="ELIMINADO" value="ELIMINADO">
                  ELIMINADO
                </option>
              </Select>
            </Grid>
            <Grid item xs={1} className={classes.formControl}>
              <TextField
                size="small"
                autoComplete="off"
                fullWidth
                required
                name="channel"
                label="Canal"
                value={this.state.form.channel}
                onChange={this.handleChangeChannel}
                inputProps={{ maxLength: 9 }}
              />
            </Grid>
            <Grid item xs={2} className={classes.formControl}>
              <TextField
                size="small"
                autoComplete="off"
                fullWidth
                name="cellNumber"
                label="Celular"
                type="number"
                value={this.state.form.cellNumber}
                onChange={this.handleChange}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid item xs={3} className={classes.formControl}>
              <TextField
                size="small"
                autoComplete="off"
                fullWidth
                name="email"
                label="Correo"
                value={this.state.form.email}
                onChange={this.handleChange}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={4} className={classes.formControl}>
              <TextField
                id="startDate"
                name="startDate"
                required
                label="Fecha inicio"
                type="datetime-local"
                value={this.state.form.startDate}
                onChange={this.handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4} className={classes.formControl}>
              <TextField
                id="endDate"
                name="endDate"
                label="Fecha fin"
                required
                type="datetime-local"
                value={this.state.form.endDate}
                onChange={this.handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="flex-end">
              <Grid item>
                <Button
                    size="small"
                    variant="contained"
                    onClick={this.props.handleClose}
                  >
                    Cancelar
                </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      id="btnSearchAll"
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={this.onClickSearch}
                    >
                      Buscar
              </Button>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.table}>
          {this.state.resultsIsOpen &&
            this.state.data.length <= 1000 &&
            ContactsResults(
              this.state.resultsIsOpen,
              this.state.data,
              this.handleCloseResults
            )}
        </div>
        <div className={classes.table}>
          {this.state.resultsIsOpen &&
            this.state.data.length > 1000 &&
            <DownloadCsv
              title="Resultados Búsqueda Contactos"
              open={this.state.resultsIsOpen}
              handleClose={this.handleCloseResults}
              data={this.state.data}
              headers={Services.contact.getContactsHeaders((AADService.auth.getRoleName().toUpperCase() === 'SUPERUSUARIO' || AADService.auth.getRoleName().toUpperCase() === 'SEGURIDAD'))}
            />}
        </div>
          </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(useStyles)(ContactForm));
