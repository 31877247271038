import React from "react";
import Select from "@material-ui/core/Select";
import { InputLabel } from "@material-ui/core";

class SelectTransaction extends React.Component {
  constructor() {
    super();
    let transactions = [
      { id: "", name: "" },
      { id: "CONSULTAR IDENTIDAD", name: "CONSULTAR IDENTIDAD" },
      { id: "CREAR IDENTIDAD", name: "CREAR IDENTIDAD" },
      { id: "ACTUALIZAR IDENTIDAD", name: "ACTUALIZAR IDENTIDAD" },
      { id: "CONSULTAR ALIAS", name: "CONSULTAR ALIAS" },
      { id: "CREAR ALIAS", name: "CREAR ALIAS" },
      { id: "ACTUALIZAR ALIAS", name: "ACTUALIZAR ALIAS" },
      { id: "REVOCAR ALIAS", name: "REVOCAR ALIAS" },
    ];
    transactions.sort((a, b) => (a.name > b.name ? 1 : -1));
    this.state = {
      data: transactions,
    };
  }

  render() {
    return (
      <React.Fragment>
        <InputLabel htmlFor="transaction" id="transactionLabel">Transacción</InputLabel>
        <Select
          native
          labelId="transactionLabel"
          value={this.props.value}
          onChange={this.props.onChange}
          displayEmpty={true}
          name="transaction"
          id="transaction"
          fullWidth
        >
          {this.state.data.map((data) => (
            <option key={data.id} value={data.id}>
              {data.name}
            </option>
          ))}
        </Select>
      </React.Fragment>
    );
  }
}
export default SelectTransaction;
