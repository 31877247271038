import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  DialogContent,
  DialogContentText,
  Select,
  MenuItem,
} from "@material-ui/core";
import Services from "../../services/JustificationService";
import Commons from "../../util/Commons";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

class ConfirmChangeStatus extends Component {
  constructor() {
    super();
    this.state = {
      justifications: [],
      justification: "",
      message: "",
      isModalOpen: false,
    };
  }

  async componentDidMount() {
    const response = await Services.justifications.getJustifications("");
    if (response.success) {
      this.setState({
        justifications: response.data.filter(obj => {
          return obj.page === this.props.page && obj.process === this.props.process
        }),
      });
    } else {
      this.setState({ justifications: [] });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleOpenDialog = (e) => {
    if (Commons.validations.hasNotText(this.state.justification.toString())) {
      this.setState({
        ...this.state,
        message: "La justificación es requerida.",
      });
    } else {
      this.setState({
        ...this.state,
        isModalOpen: true,
        message: "",
      });
    }
  };

  handleCloseDialog = (e) => {
    this.setState({
      ...this.state,
      isModalOpen: false,
    });
  };

  handleAccept = (e) => {
    let justification = null;
    justification = this.state.justifications.find(obj => obj.id === this.state.justification);
    this.props.handleAccept(justification.data);
  };

  render() {

    if (this.state.isModalOpen) {
      return (
        <ConfirmDialog
          open={this.state.isModalOpen}
          handleClose={this.handleCloseDialog}
          handleAccept={this.handleAccept}
          title={this.props.alertMessage}
        />
      );
    }

    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.handleClose}>
          <DialogTitle>{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.props.message}</DialogContentText>
            <Select
              id="justification"
              name="justification"
              value={this.state.justification}
              onChange={this.handleChange}
              displayEmpty={true}
              fullWidth
            >
              {this.state.justifications.map((justification) => (
                <MenuItem key={justification.id} value={justification.id}>
                  {justification.data}
                </MenuItem>
              ))}
            </Select>
            <br />
            <div>
              <p id="errorMessage" style={{ color: "red" }}>
                {this.state.message}
              </p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="contained" onClick={this.props.handleClose}>Cancelar</Button>
            <Button size="small" variant="contained" autoFocus color="primary" onClick={this.handleOpenDialog}>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ConfirmChangeStatus;
