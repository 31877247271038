import React from "react";
import Services from "../../services/LogsService";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress, Typography } from "@material-ui/core";
import Modal from "../../components/Modal/Modal";
import Commons from "../../util/Commons";
import logo from "../../images/Grupo-Bancolombia.png";
const useStyles = Commons.styles.getStyles();

class AdminLog extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      modalIsOpen: true,
      modalMessage: "",
      resultsIsOpen: false,
      form: {
        consumer: "",
        process: "",
        documentType: "",
        document: "",
        userAdmin: "",
        roleAdmin: "",
        startDate: Commons.date.getIsoStartOfDayDate(),
        endDate: Commons.date.getIsoCurrentDate(),
      },
    };
    this.baseState = this.state;
  }

  componentDidMount() {
    this.setState({
      loading: false
    });
  }

  onClickSearch = async (e) => {
    this.setState({ loading: true });
    const response = await Services.logs.getAdminLog(
      this.state.form,
      this.props.userName
    );
    if (response.data != null) {
      this.setState({
        loading: false,
        resultsIsOpen: true,
        data: response.data,
      });
    } else {
      this.setState({
        loading: false,
        resultsIsOpen: false,
        data: null,
        modalIsOpen: true,
        modalMessage: response.message,
      });
    }
  };

  handleClear = () => {
    this.setState(this.baseState);
    this.setState({ loading: false });
  };

  onChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleCloseModal = (e) => {
    this.setState({ modalIsOpen: false });
  };

  handleCloseResults = (e) => {
    this.setState({ resultsIsOpen: false });
  };

  render() {
    const { classes } = this.props;

    if (this.state.loading === true) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }

    if (this.state.modalIsOpen) {
      return (
        <Modal
          isOpen={this.state.modalIsOpen}
          onClose={this.handleCloseModal}
          title="...Lo sentimos..."
          message={"Esta funcionalidad no está disponible en este momento"}
        />
      );
    }

    return (
      <div>
        <Typography variant="h5" color="textPrimary" align ="center">
            Estamos trabajando en esta funcionalidad para brindarle una mejor experiencia
        </Typography>
        <div align ="center">
            <img src={logo} alt="Logo" width="70%" height="40%" />
        </div>
      </div>
      
    )
  }
}
export default withStyles(useStyles)(AdminLog);
