import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline, Tooltip } from "@material-ui/core";
import AADService from "../../services/AADService";
const useStyles = (theme) => ({
  root: {
    flexGrow: 10,
  },
  exitButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  bigAvatar: {
    margin: 10,
    width: 40,
    height: 35,
    right: "10%",
  },
});

class Header extends React.Component {

  logout() {
    AADService.auth.logout();
  }

  render() {
    const { classes } = this.props;



    return (
    
      <div  className={classes.root}>
        <CssBaseline>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Módulo Administrativo Seguridad
              </Typography>
              <Typography variant="subtitle1">
                ¡Hola {AADService.auth.getUserGivenName()}!
              </Typography>
              <Tooltip title="Cerrar sesión">
                <IconButton
                  edge="start"
                  className={classes.exitButton}
                  color="inherit"
                  aria-label="language"
                  onClick={this.logout}
                >
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
        </CssBaseline>
      </div>
    );
  }
}

export default withStyles(useStyles)(Header);
