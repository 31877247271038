import React from "react";
import {
  TextField,
  Grid,
  Container,
  CircularProgress,
  Button,
  Breadcrumbs,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import IdentityInfo from "./IdentityInfo";
import Services from "../../services/IdentityService";
import Modal from "../../components/Modal/Modal";
import SelectDocumentType from "../../components/Selects/SelectDocumentType";
import Commons from "../../util/Commons";

const useStyles = Commons.styles.getStyles();

class Identity extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      modalIsOpen: false,
      modalMessage: null,
      data: null,

      form: {
        documentType: "CC",
        document: "",
      },
    };
    this.baseState = this.state;
  }

  handleCloseModal = (e) => {
    this.setState({ modalIsOpen: false });
  };

  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  resetForm = () => {
    this.setState(this.baseState);
    this.setState({ loading: false });
  };

  handleSubmit = async (e) => {
    this.setState({ loading: true });
    const response = await Services.identities.findByTypeAndDoc(
      this.state.form,
      this.props.userName
    );
    if (response.success && response.data != null) {
      this.setState({
        loading: false,
        data: response.data,
      });
    } else {
      this.setState({
        loading: false,
        data: null,
        modalIsOpen: true,
        modalMessage: response.message,
      });
    }
  };

  handleChangeStatus = async (justification) => {
    this.setState({ loading: true });
    const response = await Services.identities.changeStatus(
      {
        cid: this.state.data.id,
        justification: justification,
      },
      this.props.userName
    );
    this.setState({
      ...this.state,
      loading: false,
      modalIsOpen: true,
      modalMessage: response.message,
    });
    if (response.success) {
      this.handleSubmit();
    } else {
      this.setState({
        ...this.state,
        data: null,
      });
    }
  };

  componentDidMount() {
    this.setState({ loading: false, data: null });
  }

  render() {
    const { classes } = this.props;

    if (this.state.loading === true) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }

    if (this.state.modalIsOpen) {
      return (
        <Modal
          onClose={this.handleCloseModal}
          isOpen={this.state.modalIsOpen}
          title="Identidad"
          message={this.state.modalMessage}
        />
      );
    }

    return (
      <React.Fragment>
        <Breadcrumbs className={classes.breadcrumbs}>
          <Typography variant="h5" color="primary">
            SUID
          </Typography>
          <Typography variant="h5" color="textPrimary">
            Identidad
          </Typography>
        </Breadcrumbs>
        <Container component="main" maxWidth="sm">
          <Grid className={classes.container} container>
            <Grid item xs={6}>
              <SelectDocumentType
                value={this.state.form.documentType}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={4} className={classes.formControl}>
              <TextField
                id="document"
                name="document"
                label="Documento"
                onChange={this.handleChange}
                value={this.state.form.document}
              />
            </Grid>
            <Grid item xs={1} className={classes.formControl}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                id="btnSearchIdentity"
                onClick={this.handleSubmit}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </Container>
        {this.state.data && (
          <IdentityInfo
            form={this.state.form}
            onReset={this.resetForm}
            data={this.state.data}
            onChangeStatus={this.handleChangeStatus}
          ></IdentityInfo>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(useStyles)(Identity));
