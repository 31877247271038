import Services from "./Services";

const CONTEXT_GET = "/justification/getAll";
const CONTEXT_SAVE = "/justification/save";
const CONTEXT_DELETE = "/justification/delete";

const services = {
  justifications: {
    getJustifications() {
      var options = {};
      options.method = "GET";
      return Services.rest.callApi(CONTEXT_GET, options);
    },
    saveJustification(request) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify(request);
      return Services.rest.callApi(CONTEXT_SAVE, options);
    },
    deleteJustification(request) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify(request);
      return Services.rest.callApi(CONTEXT_DELETE, options);
    },
    getArrayData(results) {
      var data = results.map((justification) => {
        return {
          id: justification.id,
          data: justification.data,
        };
      });
      data.sort((a, b) => (a.code > b.code ? 1 : -1));
      return data;
    },
  },
};

export default services;
