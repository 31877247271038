import React from "react";
import Services from "../../services/ContactService";
import {
  CircularProgress,
  Container,
  Grid,
  TextField,
  Button,
  Breadcrumbs,
  Typography,
  GridListTile
} from "@material-ui/core";
import Modal from "../../components/Modal/Modal";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import SelectDocumentType from "../../components/Selects/SelectDocumentType";
import MaterialTable from "material-table";
import Commons from "../../util/Commons";
import ListAltIcon from '@material-ui/icons/ListAlt';
import ContactInfo from "./ContactInfo";
import AADService from "../../services/AADService";
import DeleteIcon from '@material-ui/icons/Delete';
import ContactForm from "./ContactForm";
import ConfirmDeleteContact from "../../components/ConfirmDeleteContact/ConfirmDeleteContact";
import ValidateOtpDialog from "../../components/ValidateOtpDialog/ValidateOtpDialog";
import ValidateInfoDialog from "../../components/ValidateInfoDialog/ValidateInfoDialog";
import Search from "@material-ui/icons/Search";

const useStyles = Commons.styles.getStyles();
const tableOptions = Commons.materialTable.getOptions(true, true, true, 7);

class Contact extends React.Component {
  constructor() {
    super();
    tableOptions.selection = false;
    tableOptions.selectionProps = rowData => {
      const selectable = (rowData.status !== 'ELIMINADO' && rowData.status !== 'BLOQUEADO POR INTENTOS')
      rowData.tableData.disabled = !selectable;
      return {
        color: 'primary',
        disabled: !selectable
      }
    }

    this.state = {
      loading: false,
      data: null,
      selectedRow: [],
      modalIsOpen: false,
      deleteModalIsOpen: false,
      modalMessage: "",
      isInfoOpen: false,
      isFilterOpen: false,
      resultsIsOpen: false,
      otpIsOpen: false,
      validateIsOpen: false,
      revoke: false,
      otpResponse: null,
      validateResponse: null,
      form: {
        documentType: "CC",
        document: "",
      },
    };
    this.baseState = this.state;
  }

  handleCloseModal = (e) => {
    this.setState({ modalIsOpen: false });
  };

  handleCloseModalDelete = (e) => {
    this.setState({ deleteModalIsOpen: false });
    this.handleSubmit();
  };

  handleCloseDelete = (e, reason) => {
    if (reason && reason === "backdropClick")
        return;
        this.setState({ revoke: false });
    };

  handleCloseOtp = (e, reason) => {
    if (reason && reason === "backdropClick")
          return;
    this.setState({ otpIsOpen: false });
  };

  handleCloseValidate = (e, reason) => {
    if (reason && reason === "backdropClick")
          return;
    this.setState({ validateIsOpen: false });
  };

  handleSearch = () => {
    this.setState({
      isFilterOpen: true,
    });
  };

  handleCloseResults = (e) => {
    this.setState({ resultsIsOpen: false });
  };

  handleCloseInfo = (e) => {
    this.setState({ isInfoOpen: false });
  };

  handleEditRow = (selectedRow) => {
    this.setState({
      selectedRow: [selectedRow],
      isInfoOpen: true,
      isFilterOpen: false,
    });
  };

  handleRevokeRow = (selectedRow) => {
    this.setState({
      selectedRow: [selectedRow],
      revoke: true,
      isFilterOpen: false
    });
  };

  handleOtpRow = (selectedRow) => {
    this.setState({
      selectedRow: [selectedRow],
      loading: true
    });
    this.handleOtp(selectedRow.id);
  };

  handleValidateInfoRow = (selectedRow) => {
    this.setState({
      selectedRow: [selectedRow],
      loading: true,
      validateIsOpen: true
    });
  };

  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  resetForm = () => {
    this.setState(this.baseState);
    this.setState({ loading: false });
  };

  handleCloseForms = () => {
    this.setState(this.baseState);
  };

  handleCloseDialog = (e) => {
    this.resetForm();
    this.setState({
      isModalOpen: false,
    });
  };

  onClickSearch = async (e) => {
    this.setState({ loading: true });
    const response = await Services.contact.getContactsAll(
      this.state.form,
      this.props.userName
    );
    if (response.data != null) {
      this.setState({
        loading: false,
        resultsIsOpen: true,
        data: response.data,
      });
    } else {
      this.setState({
        loading: false,
        resultsIsOpen: false,
        data: null,
        modalIsOpen: true,
        modalMessage: response.message,
      });
    }
  };

  handleClear = () => {
    this.setState(this.baseState);
    this.setState({ loading: false });
  };

  handleSubmit = async () => {
    if(this.state.form.documentType === "" || this.state.form.document === ""){
      this.setState({
        loading: false,
        resultsIsOpen: false,
        data: null,
        modalIsOpen: true,
        modalMessage: "Faltan datos requeridos *"
      });
    } else {
      this.setState({ loading: true, data: null });
      const response = await Services.contact.findByTypeAndDoc(
        this.state.form,
        this.props.userName
      );
      if (response.success && response.data != null) {
        this.setState({
          loading: false,
          data: response.data,
        });
      } else {
        this.setState({
          loading: false,
          modalIsOpen: true,
          modalMessage: response.message,
        });
      }
    }    
  };

  handleChangeStatus = async () => {
    this.setState({ loading: true });
    const response = await Services.contact.changeStatus(
      this.state.selectedRow[0].id,
      this.state.selectedRow[0].status,
    );
    this.setState({
      ...this.state,
      loading: false,
      modalIsOpen: true,
      modalMessage: response.message,
    });
    if (response.success) {
      this.handleSubmit();
      this.setState({
        ...this.state,
        isInfoOpen: false,
      });
    }
  };

  handleRevoke = async (justification) => {
    this.setState({ loading: true, revoke: false });
    const response = await Services.contact.revoke(
      justification, this.state.selectedRow[0].id
    );
    this.setState({
      ...this.state,
      loading: false,
      deleteModalIsOpen: true,
      modalMessage: response.message,
    });
    if (response.success) {
      this.setState({
        ...this.state,
        isInfoOpen: false,
      });
    }
  };

  handleOtp = async (id) => {
      const response = await Services.contact.generateOtp(
        id
      );
      this.setState({
        ...this.state,
        loading: false
      });
      if (response.success) {
          this.setState({
            loading: false,
            otpIsOpen: true,
            otpResponse: response.data,
          });
          this.setState({
            ...this.state
          });
      } else {
        this.setState({
          loading: false,
          modalIsOpen: true,
          modalMessage: response.message,
        });
      }
    };

  handleValidateOtp = async (id, otp) => {
        const response = await Services.contact.validateOtp(
          id, otp
        );
        this.setState({
          ...this.state,
          loading: false,
          otpIsOpen: true
        });
        if (response.success) {
            this.setState({
              loading: false,
              otpCode: response.data,
            });
            this.setState({
              ...this.state,
              otpIsOpen: false
            });
            this.handleRevokeRow(this.state.selectedRow[0]);
        } else {
          this.setState({
            loading: false,
            modalIsOpen: true,
            modalMessage: response.message,
          });
        }
      };

  handleValidateInfo = async (id, email, cell) => {
    const response = await Services.contact.validateContactInfo(
      id, email, cell
    );
    this.setState({
      ...this.state,
      loading: false
    });
    if (response.success) {
        this.setState({
          loading: false,
          validateIsOpen: false,
          validateResponse: response.data,
        });
        this.setState({
          ...this.state,
          modalIsOpen: true,
          modalMessage: response.message,
        });
    } else {
      this.setState({
        loading: false,
        modalIsOpen: true,
        modalMessage: response.message,
      });
    }
  };

  render() {
    const { classes } = this.props;

    if (this.state.modalIsOpen) {
      return (
        <Modal
          isOpen={this.state.modalIsOpen}
          onClose={this.handleCloseModal}
          title="Contactos"
          message={this.state.modalMessage}
        />
      );
    }

    if (this.state.deleteModalIsOpen) {
      return (
        <Modal
          isOpen={this.state.deleteModalIsOpen}
          onClose={this.handleCloseModalDelete}
          title="Contactos"
          message={this.state.modalMessage}
        />
      );
    }

    if (this.state.isFilterOpen) {
      return (
        <ContactForm
          open={this.state.isFilterOpen}
          handleClose={this.handleCloseForms}
          handleAccept={this.onClickSearch}
          data={this.state.data}
        />
      );
    }

    if (this.state.loading === true && !this.state.data) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }

    if (this.state.revoke) {
      return (
        <Container component="main" maxWidth="xs">
          <ConfirmDeleteContact
            open={this.state.revoke}
            handleClose={this.handleCloseDelete}
            handleAccept={this.handleRevoke}
            page={"CONTACTOS"}
            process={"ELIMINAR CONTACTO"}
            message="Ingresa la justificación para eliminar el contacto"
            alertMessage={`Al eliminar el contacto el cliente ${this.state.selectedRow[0].clientName} no podrá usarlo en el canal
            ${this.state.selectedRow[0].consumerCreate}. ¿Desea continuar?`}
          ></ConfirmDeleteContact>
        </Container>
      );
    }

    if (this.state.otpIsOpen) {
      return (
        <Container component="main" maxWidth="xs">
          <ValidateOtpDialog
            open={this.state.otpIsOpen}
            handleClose={this.handleCloseOtp}
            handleAccept={this.handleValidateOtp}
            page={"CONTACTOS"}
            process={"VALIDAR OTP"}
            message="Ingresa el otp enviado al contacto para eliminarlo"
            alertMessage=""
            otpCode={this.state.otpCode}
            contactId={this.state.selectedRow[0].id}
            contactStatus={this.state.selectedRow[0].status}
          ></ValidateOtpDialog>
        </Container>
      );
    }

    if (this.state.validateIsOpen) {
      return (
        <Container component="main" maxWidth="xs">
          <ValidateInfoDialog
            open={this.state.validateIsOpen}
            handleClose={this.handleCloseValidate}
            handleAccept={this.handleValidateInfo}
            page={"CONTACTOS"}
            process={"VALIDAR INFO CONTACTO"}
            message="Ingresa los datos del contacto"
            alertMessage=""
            email={this.state.email}
            contactId={this.state.selectedRow[0].id}
            cell={this.state.cell}
          ></ValidateInfoDialog>
        </Container>
      );
    }

    return (
      <React.Fragment>
        <Breadcrumbs className={classes.breadcrumbs}>
          <Typography variant="h5" color="primary">
            SUID
          </Typography>
          <Typography variant="h5" color="textPrimary">
            Contactos de confianza
          </Typography>
        </Breadcrumbs>
        {!this.state.isFilterOpen && (
            <Container component="main" maxWidth="md">
              <Grid container spacing={1} className={classes.gridList}>
                <Grid item xs={4}>
                  <SelectDocumentType
                    value={this.state.form.documentType}
                    onChange={this.handleChange}
                    name="documentType"
                    required
                  />
                </Grid>
                <Grid item xs={2} className={classes.formControl}>
                  <TextField
                    id="document"
                    name="document"
                    label="Documento"
                    onChange={this.handleChange}
                    value={this.state.form.document}
                    required
                  />
                </Grid>
                <Grid item xs={1} className={classes.formControl}>
                  <Button
                    id="btnSearchContact"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={this.handleSubmit}
                  >
                    Buscar
                  </Button>
                </Grid>
                  {AADService.auth.hasAccessToResource('Contactos', 'Buscar') &&
                  <Grid item xs={3} className={classes.formControl}>
                    <Button id="btnSearchMasive" size="small" variant="contained" color="primary"
                      onClick={() => { this.handleSearch(); }} >
                      Búsqueda avanzada
                    </Button>
                  </Grid>
                  }
              </Grid>
            </Container>
          )}
        {this.state.data && !this.state.isInfoOpen && !this.state.isFilterOpen && (
          <GridListTile className={classes.table}
            key="contactsList" cols={5} style={{ height: 'auto' }}>
            <Container component="main" maxWidth="lg" className={classes.table}>
              <MaterialTable
                title=""
                icons={Commons.materialTable.getIcons()}
                data={this.state.data}
                options={tableOptions}
                localization={Commons.materialTable.getLocalization()}
                columns={[
                  {
                    title: "Alias contacto",
                    field: "contactAlias",
                  },
                  {
                    title: "Código país",
                    field: "cellIndicator",
                    type: "numeric",
                  },
                  {
                    title: "Celular",
                    field: "cellNumber",
                    type: "numeric",
                  },
                  {
                    title: "Correo",
                    field: "email",
                  },
                  {
                    title: "Canal de registro",
                    field: "consumerCreate",
                  },
                  {
                    title: "Estado",
                    field: "status",
                  },
                ]}
                actions={[
                  {
                    position: "row",
                    icon: () => <ListAltIcon />,
                    tooltip: "Ver detalles",
                    onClick: (event, rowData) => {
                      if (rowData) {
                        this.handleEditRow(rowData);
                      }
                    },
                  },
                  {
                    position: "row",
                    icon: () => <DeleteIcon />,
                    tooltip: "Eliminar contacto",
                    hidden: !AADService.auth.hasAccessToResource('Contactos', 'EliminarJustificacion'),
                    onClick: (event, rowData) => {
                      if (rowData) {
                        this.handleRevokeRow(rowData);
                      }
                    },
                  },
                  {
                      position: "row",
                      icon: () => <DeleteIcon />,
                      tooltip: "Eliminar contacto",
                      hidden: !AADService.auth.hasAccessToResource('Contactos', 'EliminarOTP') ,
                      onClick: (event, rowData) => {
                        if (rowData) {
                          this.handleOtpRow(rowData);
                        }
                      },
                    },
                  {
                    position: "row",
                    icon: () => <Search />,
                    tooltip: "Validar datos",
                    hidden: !AADService.auth.hasAccessToResource('Contactos', 'VerificarDatos'),
                    onClick: (event, rowData) => {
                      if (rowData) {
                        this.handleValidateInfoRow(rowData);
                      }
                    },
                  },
                ]}
              />
            </Container>
          </GridListTile>
        )}
        {this.state.isInfoOpen &&
          <GridListTile  className={classes.table}
            key="contactInfo" cols={3} style={{ height: 'auto' }}>
            <ContactInfo
              data={this.state.selectedRow[0]}
              onClose={this.handleCloseInfo}
              onChangeStatus={this.handleChangeStatus}
              onRevoke={this.handleRevoke}
            />
          </GridListTile>
        }
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(useStyles)(Contact));
