import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

class ChangeStatusDialog extends Component {
  constructor() {
    super();
    this.state = {
      message: "",
      isModalOpen: false,
    };
  }

  handleAccept = (e) => {
    this.props.handleAccept(e);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleOpenDialog = (e) => {
      this.setState({
        ...this.state,
        isModalOpen: true,
        message: "",
      });
  };

  handleCloseDialog = (e) => {
    this.setState({
      ...this.state,
      isModalOpen: false,
    });
  };

  render() {

    if (this.state.isModalOpen) {
      return (
        <ConfirmDialog
          open={this.state.isModalOpen}
          handleClose={this.handleCloseDialog}
          handleAccept={this.handleAccept}
          title={this.props.alertMessage}
        />
      );
    }

    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.handleClose}>
          <DialogTitle>{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.props.message}</DialogContentText>
            <div>
              <p id="errorMessage" style={{ color: "red" }}>
                {this.state.message}
              </p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="contained" onClick={this.props.handleClose}>Cancelar</Button>
            <Button size="small" variant="contained" autoFocus color="primary" onClick={this.handleAccept}>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ChangeStatusDialog;
