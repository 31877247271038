import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CSVLink } from "react-csv";
import { DialogContent } from "@material-ui/core";

class DownloadCsv extends Component {
  render() {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.handleClose}>
          <DialogTitle id="lblDialogTitle">{this.props.title}</DialogTitle>
          <DialogContent>La búsqueda ha retornado más de 1000 registros (último registro en descarga: { this.props.last})
            por lo que no es posible mostrarlos en la página web, se descargan máximo 8000 registros,
            En caso de tener más de 8000 registros, ajustar la fecha de búsqueda para descargar los registros restantes</DialogContent>
          <DialogActions>
            <CSVLink data={this.props.data} headers={this.props.headers} filename={this.props.title +"_"+new Date().toLocaleString()+ ".csv"}>
              Descargar archivo CSV
            </CSVLink>
          </DialogActions>
        </Dialog>
      </div >
    );
  }
}

export default withRouter(DownloadCsv);
