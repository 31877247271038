import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import {
  Grid,
  InputLabel,
  Select,
  CssBaseline,
  Container,
  Breadcrumbs,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Commons from "../../util/Commons";
import InfoTooltip from "../../components/InfoTooltip/InfoTooltip"
import SelectSegment from "../../components/Selects/SelectSegment"
import AADService from "../../services/AADService";

const useStyles = Commons.styles.getStyles();

class ConsumerForm extends Component {
  constructor(props) {
    super();
    this.state = {
      form: {
        id: props.data ? props.data.id : null,
        acronym: props.data ? props.data.acronym : '',
        name: props.data ? props.data.name : '',
        status: props.data ? props.data.status : 'ACTIVO',
        duplicateAlias: props.data ? (props.data.duplicateAlias === 'true') : true,
        sharedManagement: props.data ? (props.data.sharedManagement === 'true') : true,
        description: props.data ? props.data.description : '',
        inOperation: props.data ? props.data.inOperation : true,
        allowCreateUsers: props.data ? props.data.allowCreateUsers : true,
        multipleAlias: props.data ? props.data.multipleAlias : false,
        statusDepuration: props.data ? props.data.statusDepuration : false,
        aliasRemainTime: Commons.date.getIsoCurrentDate(),
        segment: (props.data && props.data.segment !== null) ? props.data.segment : '',
        depurationAlias: props.data ? props.data.depurationAlias : '',
        timeDepuration: props.data ? props.data.timeDepuration : '',
        rememberTime: props.data ? props.data.rememberTime: '',
      },
      isModalOpen: false,
      modalMessage: "",
      isMultipleAliasAlertOpen: false,
      istatusDepurationOpen: false
    };
  }

  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeChecked = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.checked,
      },
    });
  };

  handleCheckMultipleAlias = (e) => {
    if (e.target.checked) {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form
        },
        isMultipleAliasAlertOpen: true
      });
    } else {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          multipleAlias: false
        },
        isMultipleAliasAlertOpen: false
      });
    }
  };

  handleCheckStatusDepuration = (e) => {
    if (e.target.checked) {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          statusDepuration: true
        },
      });
    } else {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          statusDepuration: false
        },
      });
    }
  };

  handleConfirmMultipleAlias = (e) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        multipleAlias: true
      },
      isMultipleAliasAlertOpen: false
    });
  };

  handleOpenDialog = (e) => {
    let errors = this.validateForm();
    this.setState({
      ...this.state,
      isModalOpen: Commons.validations.hasNotText(errors),
      modalMessage: errors,
    });
  };

  validateForm = () => {
    var errors = "";
    if (Commons.validations.hasNotText(this.state.form.acronym)) {
      errors += "Acrónimo";
    }
    if (Commons.validations.hasNotText(this.state.form.name)) {
      errors += errors === "" ? "Nombre" : ", Nombre";
    }

    if (errors !== "") {
      errors = "Los siguientes datos son requeridos: " + errors;
    }
    return errors;
  };

  handleCloseDialog = (e) => {
    this.setState({
      isModalOpen: false,
      isMultipleAliasAlertOpen: false
    });
  };

  handleConfirm = (e) => {
    this.props.handleAccept(this.state.form);
  };

  handleKeyPressed = async (e) => {
    if (e.key === "Enter") {
      this.setState({
        isModalOpen: true,
      });
    }
  };

  render() {
    const { classes } = this.props;

    if (this.state.isModalOpen) {
      return (
        <ConfirmDialog
          title="¿Desea guardar el consumidor?"
          open={this.state.isModalOpen}
          handleClose={this.handleCloseDialog}
          handleAccept={this.handleConfirm}
        />
      );
    }

    if (this.state.isMultipleAliasAlertOpen) {
      return (
        <ConfirmDialog
          title="Este parámetro aplica solo para canales persona jurídica ¿Desea activarlo?"
          open={this.state.isMultipleAliasAlertOpen}
          handleClose={this.handleCloseDialog}
          handleAccept={this.handleConfirmMultipleAlias}
        />
      );
    }

    return (
      <React.Fragment>
        <Breadcrumbs className={classes.breadcrumbs}>
          <Typography variant="h5" color="primary">
            SUID
          </Typography>
          <Typography variant="h5" color="textPrimary">
            Consumidores
          </Typography>
          <Typography variant="h5" color="textPrimary">
            {this.state.form.id === null
              ? "Registrar Consumidor"
              : "Actualizar Consumidor"}
          </Typography>
          <InfoTooltip placement="right" title="Consumidor interno o externo que consulta el servicio de identificación SUID. Este concepto de consumidor aplica también  para servicios expuestos por el Banco que  requieran consultar el servicio de identificación SUID. Ejemplo: Servicio CORE , canales virtuales, electrónicos, físicos, entro otros." />
        </Breadcrumbs>
        <Container maxWidth="md">
          <CssBaseline />
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <TextField
                size="small"
                autoFocus
                autoComplete="off"
                fullWidth
                name="acronym"
                label="Acrónimo*"
                value={this.state.form.acronym}
                onChange={this.handleChange}
                inputProps={{ maxLength: 9 }}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                size="small"
                autoComplete="off"
                fullWidth
                name="name"
                label="Nombre*"
                value={this.state.form.name}
                onChange={this.handleChange}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={2}>
              <InputLabel id="statusLabel">Estado</InputLabel>
              <Select
                native
                labelId="statusLabel"
                name="status"
                margin="dense"
                fullWidth
                value={this.state.form.status}
                onChange={this.handleChange}
                displayEmpty={true}
                disabled={!AADService.auth.hasAccessToResource('Consumidores', 'CambioEstado')}
              >
                <option key="0" value="ACTIVO">
                  ACTIVO
                </option>
                <option key="1" value="INACTIVO">
                  INACTIVO
                </option>
              </Select>
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.form.duplicateAlias} color="primary"
                    onChange={this.handleChangeChecked} name="duplicateAlias"
                  />
                }
                label="Compartir alias" labelPlacement="start"
              />
            </Grid>
            <Grid item xs={1}>
              <InfoTooltip placement="bottom" title="Permite al consumidor reutilizar un alias generado previamente en otro consumidor." />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={<Checkbox checked={this.state.form.sharedManagement} color="primary"
                  onChange={this.handleChangeChecked} name="sharedManagement"
                />}
                label="Administración compartida" labelPlacement="start"
              />
            </Grid>
            <Grid item >
              <InfoTooltip placement="bottom" title="Permite al consumidor aplicar una modificación de un alias en otros consumidores." />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={<Checkbox checked={this.state.form.inOperation} color="primary"
                  onChange={this.handleChangeChecked} name="inOperation"
                />}
                label="En operación" labelPlacement="start"
              />
            </Grid>
            <Grid item xs={1}>
              <InfoTooltip placement="bottom" title="Indica que el canal se encuentra activo para los clientes." />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={<Checkbox checked={this.state.form.allowCreateUsers} color="primary"
                  onChange={this.handleChangeChecked} name="allowCreateUsers"
                />}
                label="Gestión de usuarios" labelPlacement="start"
              />
            </Grid>
            <Grid item xs={1}>
              <InfoTooltip
                placement="bottom"
                title="Cliente: Posee productos banco.
                        Usuario: Utiliza servicios banco.
                        Gestión de usuarios: Habilita el canal para la creación de identidades y de alias de usuarios."
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox checked={this.state.form.multipleAlias} color="primary"
                  onChange={this.handleCheckMultipleAlias} name="multipleAlias"
                />}
                label="Múltiples alias" labelPlacement="start"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={<Checkbox checked={this.state.form.statusDepuration} color="primary"
                  onChange={this.handleChangeChecked} name="statusDepuration"
                />}
                label="Depuración de Alias" labelPlacement="start"
              />
            </Grid>
            <Grid item xs={2}>
              <InputLabel id="Depurationlabel">Tiempo de Depuración</InputLabel>
              <Select
                native
                labelId="Depurationlabel"
                name="depurationAlias"
                margin="dense"
                fullWidth
                value={this.state.form.depurationAlias}
                onChange={this.handleChange}
                displayEmpty={true}
                disabled={!this.state.form.statusDepuration}
              >
                <option key="0" value="">
                  
                </option>
                <option key="1" value="1">
                  1 Mes
                </option>
                <option key="2" value="2">
                  2 Meses
                </option>
                <option key="3" value="3">
                  3 Meses
                </option>
                <option key="4" value="4">
                  4 Meses
                </option>
                <option key="5" value="5">
                  5 Meses
                </option>
                <option key="6" value="6">
                  6 Meses
                </option>
              </Select>
            </Grid>
            <Grid item xs={1}>
              <InfoTooltip
                placement="bottom"
                title="Indica el perdíodo de tiempo, en meses, en el que se correrá el proceso de depuración de Alias inactivos en el canal"
              />
            </Grid>
            <Grid item xs={2}>
              <InputLabel id="Depurationlabel">Tiempo de recordación de usuario</InputLabel>
              <TextField
                size="small"
                autoComplete="off"
                fullWidth
                name="rememberTime"
                value={this.state.form.rememberTime}
                onChange={this.handleChange}
                inputProps={
                  { readOnly: true, }
                }
              />
            </Grid>
            <Grid item xs={2}>
              <SelectSegment
                value={this.state.form.segment}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="aliasRemainTime"
                label="Fecha de caducidad de los alias"
                type="datetime-local"
                value={this.state.form.aliasRemainTime}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={1}>
              <InfoTooltip placement="bottom" title="Indica el período de tiempo en que el alias permanecerá activo luego de la salida de operación de un canal." />
            </Grid>
            <Grid item xs={5}>
              <TextField
                autoComplete="off"
                fullWidth
                name="description"
                label="Descripción"
                value={this.state.form.description}
                onChange={this.handleChange}
                inputProps={{ maxLength: 200 }}
              />
            </Grid>
            <Grid item xs={12}>
              <p id="errorMessage" style={{ color: "red", fontSize: "15px" }}>
                {this.state.modalMessage}
              </p>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-end"
            >
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  onClick={this.props.handleClose}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={this.handleOpenDialog}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(useStyles)(ConsumerForm));
