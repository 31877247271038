import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import Commons from "../../util/Commons";
import { Container, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import AADService from '../../services/AADService';

class DocumentTypeForm extends React.Component {
	constructor(props) {
		super();
		this.state = {
			form: {
				id: props.data ? props.data.id : null,
				code: props.data ? props.data.code : "",
				name: props.data ? props.data.name : "",
				status: props.data ? props.data.status : "ACTIVO",
			},
			errorMessage: "",
			isModalOpen: false,
		};
	}

	handleChange = (e) => {
		this.setState({
			...this.state,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value,
			}
		});
	};

	handleOpenDialog = (e) => {
		if (this.validateForm()) {
			this.setState({
				...this.state,
				isModalOpen: true,
				errorMessage: "",
			});
		} else {
			this.setState({
				...this.state,
				errorMessage: "La justificación es requerida",
			});
		}
	};

	validateForm = () => {
		let response = true;
		if (Commons.validations.hasNotText(this.state.form.name)) {
			response = false;
		}
		return response;
	};

	handleCloseDialog = (e) => {
		this.setState({
			...this.state,
			isModalOpen: false,
		});
	};

	handleConfirm = (e) => {
		this.props.handleAccept(this.state.form);
	};


	handleKeyPressed = async (e) => {
		if (e.key === "Enter") {
			this.setState({
				isModalOpen: true,
			});
		}
	};

	render() {
		if (this.state.isModalOpen) {
			return (
				<ConfirmDialog
					open={this.state.isModalOpen}
					handleClose={this.handleCloseDialog}
					handleAccept={this.handleConfirm}
					title="¿Desea guardar el tipo de documento?"
				/>
			);
		}

		return (
			<div>
				<Dialog open={this.props.open} onClose={this.props.handleClose}>
					<DialogTitle id="form-dialog-title">
						{this.state.form.id ? "Actualizar tipo de documento" : "Registrar tipo de documento"}
					</DialogTitle>
					<DialogContent>
						<Container maxWidth="md">
							<Grid container spacing={1} direction="column">
								<Grid item>
									<TextField
										name="code"
										label="Código*"
										fullWidth
										value={this.state.form.code}
										onKeyPress={this.handleKeyPressed}
										onChange={this.handleChange}
										inputProps={{ maxLength: 10 }}
									/>
								</Grid>
								<Grid item>
									<TextField
										name="name"
										label="Nombre*"

										fullWidth
										value={this.state.form.name}
										onKeyPress={this.handleKeyPressed}
										onChange={this.handleChange}
										inputProps={{ maxLength: 150 }}
									/>
								</Grid>
								<Grid item>
									<InputLabel id="statusLabel">Estado</InputLabel>
									<Select
										labelId="statusLabel"
										name="status"
										margin="dense"
										fullWidth
										value={this.state.form.status}
										onChange={this.handleChange}
										displayEmpty={true}
										disabled={!AADService.auth.hasAccessToResource('TiposDocumentos', 'CambioEstado')}
									>
										<MenuItem key="0" value="ACTIVO">
											ACTIVO
                  		</MenuItem>
										<MenuItem key="1" value="INACTIVO">
											INACTIVO
                  		</MenuItem>
									</Select>
								</Grid>
							</Grid>
						</Container>
						<br />
						<div>
							<p id="errorMessage" style={{ color: "red" }}>
								{this.state.errorMessage}
							</p>
						</div>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" size="small" onClick={this.props.handleClose}>Cancelar</Button>
						<Button variant="contained" size="small" color="primary" onClick={this.handleOpenDialog}>
							Guardar
            </Button>
					</DialogActions>
				</Dialog>
			</div >
		);
	}
}

export default withRouter((DocumentTypeForm));
