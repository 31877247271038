import React from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  CircularProgress
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ChangeStatusDialog from "../../components/ChangeStatusDialog/ChangeStatusDialog";
import Commons from "../../util/Commons";
import AADService from "../../services/AADService";

const useStyles = Commons.styles.getStyles();

class ContactInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      changeStatus: false,
      loading: false,
      revoke: false,
    };
  }

  handleOpenChangeStatus = (e) => {
    this.setState({ changeStatus: true, loading: true });
  };

  handleCloseChangeStatus = (e) => {
    this.setState({ changeStatus: false });
  };

  render() {
    const { classes } = this.props;
    if (this.state.changeStatus) {
      return (
        <Container component="main" maxWidth="xs">
          <ChangeStatusDialog
            open={this.state.changeStatus}
            handleClose={this.handleCloseChangeStatus}
            handleAccept={this.props.onChangeStatus}
            message={this.props.data.status === "ACTIVO" ?
            `El contacto "${this.props.data.contactAlias}" será bloqueado por alerta de seguridad. ¿Desea continuar?` :
            `El contacto "${this.props.data.contactAlias}" será activado. ¿Desea continuar?`}
          ></ChangeStatusDialog>
        </Container>
      );
    }

    if (this.state.loading === true && !this.state.data) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Container component="main" maxWidth="md">
          <Grid container className={classes.container} spacing={1}>
              <Grid item xs={6}>
              <TextField
                  inputProps={{
                    readOnly: true,
                    style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="id"
                  label="ID"
                  value={this.props.data.id}
              />
              </Grid>
              <Grid item xs={6}>
              <TextField
                  inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="cid"
                  label="CID"
                  value={this.props.data.cid}
              />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    inputProps={{
                      readOnly: true,
                      style: { textAlign: "center" },
                    }}
                    size="small"
                    fullWidth
                    name="clientName"
                    label="Nombre cliente"
                    value={this.props.data.clientName}
                />
              </Grid>
              <Grid item xs={2}>
              <TextField
                  inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="documentType"
                  label="Tipo documento*"
                  value={this.props.data.documentType}
              />
              </Grid>
              <Grid item xs={3}>
              <TextField
                  inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="document"
                  label="Número documento"
                  value={this.props.data.document}
              />
              </Grid>
              <Grid item xs={3}>
              <TextField
                  inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="contactAlias"
                  label="Alias contacto"
                  value={this.props.data.contactAlias}
              />
              </Grid>
              <Grid item xs={4}>
              <TextField
                  inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  id="status"
                  label="Estado del contacto"
                  value={this.props.data.status}
              />
              </Grid>
              <Grid item xs={3}>
              <TextField
                  inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="cellIndicator"
                  label="Celular"
                  value={`+ ${this.props.data.cellIndicator} ${this.props.data.cellNumber}`}
              />
              </Grid>
              <Grid item xs={4}>
              <TextField
                  inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="email"
                  label="Correo"
                  value={this.props.data.email}
              />
              </Grid>
              <Grid item xs={2}>
              <TextField
                  inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="consumerCreate"
                  label="Canal de registro"
                  value={this.props.data.consumerCreate}
              />
              </Grid>       
              <Grid item xs={3}>
              <TextField
                  inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="dateCreate"
                  label="Fecha creación"
                  value={this.props.data.dateCreate}
              />
              </Grid>
              <Grid item xs={2}>
              <TextField
                  inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="consumerDelete"
                  label="Canal eliminación"
                  value={this.props.data.consumerDelete}
              />
              </Grid>
              <Grid item xs={3}>
              <TextField
                  inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="dateDelete"
                  label="Fecha eliminación"
                  value={this.props.data.dateDelete}
              />
              </Grid>
              {((AADService.auth.getRoleName().toUpperCase() === 'SUPERUSUARIO') || (AADService.auth.getRoleName().toUpperCase() === 'SEGURIDAD')) &&
                  <Grid item xs={2}>
                  <TextField
                      inputProps={{
                          readOnly: true,
                          style: { textAlign: "center" },
                      }}
                      size="small"
                      fullWidth
                      id="amountOtpGenereated"
                      label="OTP generados"
                      value={this.props.data.amountOtpGenereated}
                  />
                  </Grid>
              }
              <Grid item xs={3}>
              <TextField
                  inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="consumerUpdate"
                  label="Canal última actualización"
                  value={this.props.data.consumerUpdate}
              />
              </Grid>
              <Grid item xs={3}>
              <TextField
                  inputProps={{
                  readOnly: true,
                  style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="dateDelete"
                  label="Fecha última actualización"
                  value={this.props.data.dateUpdate}
              />
              </Grid>
              {((AADService.auth.getRoleName().toUpperCase() === 'SUPERUSUARIO') || (AADService.auth.getRoleName().toUpperCase() === 'SEGURIDAD')) &&
                  <Grid item xs={3}>
                  <TextField
                      inputProps={{
                          readOnly: true,
                          style: { textAlign: "center" },
                      }}
                      size="small"
                      fullWidth
                      id="lastOtpGenerated"
                      label="Fecha último OTP"
                      value={this.props.data.lastOtpGenerated}
                  />
                  </Grid>
              }
              <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-end">
              <Grid item>
                  <Button
                  id="btnClearContactInfo"
                  variant="contained"
                  size="small"
                  onClick={this.props.onClose}
                  >
                  Regresar
                  </Button>
              </Grid>
              {AADService.auth.hasAccessToResource('Contactos', 'CambioEstado') &&
                ((this.props.data.status === "ACTIVO") || (this.props.data.status === "BLOQUEADO POR SEGURIDAD")) &&
                <Grid item>
                  <Button
                      id="btnChangeStatusContact"
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={this.handleOpenChangeStatus}
                  >
                    {this.props.data.status === "ACTIVO"? "Bloquear Contacto" : "Activar Contacto"}
                  </Button>
                </Grid>
              }
              </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(useStyles)(ContactInfo));