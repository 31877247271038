import Services from "./Services";

const SAVE_CONTEXT = "/segment/save";
const DELETE_CONTEXT = "/segment/delete";
const GET_CONTEXT = "/segment/getAll";

const services = {
  segment: {
    saveSegment(request) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify(request);
      return Services.rest.callApi(SAVE_CONTEXT, options);
    },
    deleteSegment(request) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify(request);
      return Services.rest.callApi(DELETE_CONTEXT, options);
    },
    getSegments() {
      var options = {};
      options.method = "GET";
      return Services.rest.callApi(GET_CONTEXT, options);
    },
  },
};

export default services;
