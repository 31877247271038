import Services from "./Services";

const CONTEXT_GET = "/identity/findByTypeAndDocument";
const CONTEXT_CHANGE_STATUS = "/identity/changeStatus";
const CONTEXT_UPDATE_IDENTITY = "/identity/update";

const services = {
  identities: {
    findByTypeAndDoc(request) {
      var options = {};
      options.body = JSON.stringify(request);
      options.method = "POST";
      return Services.rest.callApi(CONTEXT_GET, options);
    },
    changeStatus(request) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify(request);
      return Services.rest.callApi(CONTEXT_CHANGE_STATUS, options);
    },
    updateIdentity(request) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify(request);
      return Services.rest.callApi(CONTEXT_UPDATE_IDENTITY, options);
    },
  },
};

export default services;
