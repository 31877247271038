import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import DocumentTypeService from "../../services/DocumentTypeService";
import MaterialTable from "material-table";
import Modal from "../../components/Modal/Modal";
import { Breadcrumbs, Button, Container, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Commons from "../../util/Commons";
import DocumentTypeForm from "./DocumentTypeForm";
import AADService from '../../services/AADService';

const useStyles = Commons.styles.getStyles();

class DocumentType extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      isModalOpen: false,
      modalMessage: null,
      isFormOpen: false,
      isDeleteOpen: false,
      selectedRow: null,
      data: [],
    };
    this.baseState = this.state;
  }

  async componentDidMount() {
    this.setState({ loading: false });
    this.fetchData();
  }

  async fetchData() {
    this.setState({ loading: true });
    const response = await DocumentTypeService.documentTypes.getDocumentTypes();
    if (response.success && response.data != null) {
      this.setState({
        loading: false,
        data: DocumentTypeService.documentTypes.getArrayData(response.data),
      });
    } else {
      this.setState({ loading: false, data: [] });
    }
  }

  saveData = async (data) => {
    this.setState({ loading: true });
    let response = await DocumentTypeService.documentTypes.saveDocumentType(data, this.props.userName);
    this.setState({
      isFormOpen: false,
      isModalOpen: true,
      loading: false,
      modalMessage: response.message,
    });
    this.fetchData();
  };

  handleEditRow = (selectedRow) => {
    this.setState({
      selectedRow: selectedRow,
      isFormOpen: true,
    });
  };

  handleAddRow = () => {
    this.setState({
      selectedRow: null,
      isFormOpen: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
      isFormOpen: false,
      modalMessage: "",
      selectedRow: null,
    });
  };

  handleCloseForms = () => {
    this.setState(this.baseState);
    this.fetchData();
  };

  render() {
    const { classes } = this.props;

    if (this.state.isModalOpen) {
      return (
        <Modal
          isOpen={this.state.isModalOpen}
          onClose={this.handleCloseModal}
          title="Tipos de documentos"
          message={this.state.modalMessage}
        />
      );
    }

    if (this.state.isFormOpen) {
      return (
        <DocumentTypeForm
          open={this.state.isFormOpen}
          handleClose={this.handleCloseForms}
          handleAccept={this.saveData}
          data={this.state.selectedRow}
        />
      );
    }

    return (
      <React.Fragment>
        <Breadcrumbs className={classes.breadcrumbs}>
          <Typography variant="h5" color="primary">
            SUID
          </Typography>
          <Typography variant="h5" color="textPrimary">
            Tipos de documentos
          </Typography>
        </Breadcrumbs>
        <div className={classes.paper}>
          {AADService.auth.hasAccessToResource('TiposDocumentos', 'Crear') &&
            <Button id="btnRegisterDocumentType" size="small" variant="contained" color="primary"
              onClick={() => { this.handleAddRow(); }} >
              Registrar tipo de documento
            </Button>
          }
          <Container component="main" maxWidth="xs">
            <MaterialTable
              title=""
              icons={Commons.materialTable.getIcons()}
              data={this.state.data}
              options={Commons.materialTable.getOptions(true, true, true)}
              localization={Commons.materialTable.getLocalization()}
              columns={[
                {
                  title: "Código",
                  field: "code",
                },
                {
                  title: "Nombre",
                  field: "name",
                  width: "500",
                },
                {
                  title: "Estado",
                  field: "status",
                },
              ]}
              actions={
                AADService.auth.hasAccessToResource('TiposDocumentos', 'Modificar') &&
                [{
                  icon: () => <EditIcon />,
                  tooltip: "Editar",
                  onClick: (event, rowData) => {
                    if (rowData) {
                      this.handleEditRow(rowData);
                    }
                  },
                },
                ]
              }
            />
          </Container>
        </div>
      </React.Fragment>

    );
  }
}
export default withRouter(withStyles(useStyles)(DocumentType));
