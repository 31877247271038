import Services from "./Services";

const CONTEXT_GET = "/documentType/getAll";
const CONTEXT_POST = "/documentType/save";

const services = {
  documentTypes: {
    getDocumentTypes() {
      var options = {};
      options.method = "GET";
      return Services.rest.callApi(CONTEXT_GET, options);
    },
    saveDocumentType(data) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify({
        id: data.id,
        code: data.code,
        name: data.name,
        status: data.status === "ACTIVO",
      });
      let response = Services.rest.callApi(CONTEXT_POST, options);
      return response;
    },
    getArrayData(results) {
      var data = results.map((documentType) => {
        return {
          id: documentType.id,
          code: documentType.code,
          name: documentType.name,
          status: documentType.status ? "ACTIVO" : "INACTIVO",
        };
      });
      data.sort((a, b) => (a.code > b.code ? 1 : -1));
      return data;
    },
  },
};

export default services;
