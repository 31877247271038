import Services from "./Services";

const GET_CONTEXT = "/alias/findByTypeAndDocument";
const CHANGE_STATUS_CONTEXT = "/alias/changeStatus";
const REVOKE_CONTEXT = "/alias/revoke";
const REVOKE_MULTIPLE_CONTEXT = "/alias/revokeMultiple";

const services = {
  alias: {
    findByTypeAndDoc(request) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify(request);
      return Services.rest.callApi(GET_CONTEXT, options);
    },
    changeStatus(justification, aid) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify({
        aid: aid,
        justification: justification,
      });
      return Services.rest.callApi(CHANGE_STATUS_CONTEXT, options);
    },
    revoke(justification, aid) {
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify({
        aid: aid,
        justification: justification,
      });
      return Services.rest.callApi(REVOKE_CONTEXT, options);
    },
    revokeMultiple(justification, rows) {
      let requests = [];
      rows.forEach(row => {
        requests.push({
          aid: row.aid,
          justification: justification,
        })
      });
      var options = {};
      options.method = "POST";
      options.body = JSON.stringify(requests);
      return Services.rest.callApi(REVOKE_MULTIPLE_CONTEXT, options);
    },
  },
};

export default services;
