import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MaterialTable from "material-table";
import Commons from "../../util/Commons";
import AADService from "../../services/AADService";

export default function FunctionalLogResults(open, data, handleClose) {
  

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogContent>
          <MaterialTable
            title="Resultados Log Funcional"
            icons={Commons.materialTable.getIcons()}
            data={data}
            options={
              Commons.materialTable.getOptions(true, true, AADService.auth.hasAccessToResource('LogFuncional', 'Exportar'))
            }
            localization={Commons.materialTable.getLocalization()}
            columns={[
              {
                title: "Id",
                field: "id",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Channel",
                field: "app",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Tipo de documento",
                field: "document_type",
                editable: "never",
                width: 70,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Documento",
                field: "document",
                editable: "never",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Clasificacion",
                field: "classification",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Accion",
                field: "action",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Proceso",
                field: "process",
                width: 200,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Id Transacción",
                field: "transactionalId",
                width: 200,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Fecha",
                field: "creationDate",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Descripcion",
                field: "description",
                width: 200,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Actor",
                field: "actor",
                width: 200,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Codigo Transacción",
                field: "transactionResultCode",
                width: 200,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Descripcion Transacción",
                field: "transactionResultDescription",
                width: 200,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Source Id",
                field: "sourceId",
                width: 200,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Zona Horaria",
                field: "timeZone",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "ID Global",
                field: "globalId",
                width: 200,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Valor Previo",
                field: "beforeValue",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Valor Resultante",
                field: "afterValue",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Info",
                field: "additionalInfo",
                width: 300,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={handleClose}
            color="primary"
            autoFocus
          >
            Regresar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
