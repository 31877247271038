import React from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ConfirmChangeStatus from "../../components/ConfirmChangeStatus/ConfirmChangeStatus";
import Commons from "../../util/Commons";
import AADService from "../../services/AADService";

const useStyles = Commons.styles.getStyles();

class AliasInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      changeStatus: false,
      revoke: false,
    };
  }

  handleOpenChangeStatus = (e) => {
    this.setState({ changeStatus: true });
  };

  handleCloseChangeStatus = (e) => {
    this.setState({ changeStatus: false });
  };

  handleOpenRevoke = (e) => {
    this.setState({ revoke: true });
  };

  handleCloseRevoke = (e) => {
    this.setState({ revoke: false });
  };

  render() {
    const { classes } = this.props;
    if (this.state.changeStatus) {
      return (
        <Container component="main" maxWidth="xs">
          <ConfirmChangeStatus
            open={this.state.changeStatus}
            handleClose={this.handleCloseChangeStatus}
            handleAccept={this.props.onChangeStatus}
            page={"ALIAS"}
            process={this.props.data.statusConsumer === "ACTIVO" ? "INACTIVAR ALIAS" : "ACTIVAR ALIAS"}
            message={this.props.data.statusConsumer === "ACTIVO" ?
              "Seleccione la justificación para inactivar el alias" :
              "Seleccione la justificación para activar el alias"}
            alertMessage={this.props.data.statusConsumer === "ACTIVO" ?
              `Al inactivar el alias se bloquea el ingreso de ${this.props.data.identityName} en el canal ${this.props.data.app}. ¿Desea continuar?` :
              `Al activar el alias se permite el ingreso de ${this.props.data.identityName} en el canal ${this.props.data.app}. ¿Desea continuar?`}
          ></ConfirmChangeStatus>
        </Container>
      );
    }

    if (this.state.revoke) {
      return (
        <Container component="main" maxWidth="xs">
          <ConfirmChangeStatus
            open={this.state.revoke}
            handleClose={this.handleCloseRevoke}
            handleAccept={this.props.onRevoke}
            page={"ALIAS"}
            process={"ELIMINAR ALIAS"}
            message="Seleccione la justificación para eliminar el alias"
            alertMessage={`Al eliminar el alias se bloquea el ingreso de ${this.props.data.identityName} en el canal ${this.props.data.app}. ¿Desea continuar?`}
          ></ConfirmChangeStatus>
        </Container>
      );
    }

    return (
      <React.Fragment>
        <Container component="main" maxWidth="md">
          <div className={classes.paper}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  inputProps={{
                    readOnly: true,
                    style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="aid"
                  label="Aid"
                  value={this.props.data.aid}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    readOnly: true,
                    style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="consumer"
                  label="Consumidor asociado"
                  value={this.props.data.app}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    readOnly: true,
                    style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  id="statusConsumer"
                  label="Estado en el consumidor"
                  value={this.props.data.statusConsumer}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    readOnly: true,
                    style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="statusAlias"
                  label="Estado del alias"
                  value={this.props.data.statusAlias}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    readOnly: true,
                    style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="appCreate"
                  label="Consumidor de registro"
                  value={this.props.data.appCreate + " "}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    readOnly: true,
                    style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="dateCreate"
                  label="Fecha de registro"
                  value={this.props.data.dateCreate + " "}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    readOnly: true,
                    style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  id="timeCreate"
                  label="Hora de registro"
                  value={this.props.data.timeCreate + " "}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    readOnly: true,
                    style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="appUpdate"
                  label="Consumidor última actualización"
                  value={this.props.data.appUpdate + " "}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    readOnly: true,
                    style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  id="dateUpdate"
                  label="Fecha de actualización"
                  value={this.props.data.dateUpdate + " "}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    readOnly: true,
                    style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  id="timeUpdate"
                  label="Hora de actualización"
                  value={this.props.data.timeUpdate + " "}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    readOnly: true,
                    style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="appRevoke"
                  label="Consumidor eliminación"
                  value={this.props.data.appRevoke + " "}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    readOnly: true,
                    style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  name="dateRevoke"
                  label="Fecha de eliminación"
                  value={this.props.data.dateRevoke + " "}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    readOnly: true,
                    style: { textAlign: "center" },
                  }}
                  size="small"
                  fullWidth
                  id="timeRevoke"
                  label="Hora de eliminación"
                  value={this.props.data.timeRevoke + " "}
                />
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-end">
                <Grid item>
                  <Button
                    id="btnClearAliasInfo"
                    variant="contained"
                    size="small"
                    onClick={this.props.onClose}
                  >
                    Regresar
                  </Button>
                </Grid>
                {AADService.auth.hasAccessToResource('Alias', 'CambioEstado') &&
                  this.props.data.statusAlias === "ACTIVO" &&
                  <Grid item>
                    <Button
                      id="btnChangeStatusAlias"
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={this.handleOpenChangeStatus}
                    >
                      Cambiar Estado
                    </Button>
                  </Grid>
                }
                {AADService.auth.hasAccessToResource('Alias', 'Revocar') &&
                  this.props.data.statusAlias === "ACTIVO" &&
                  <Grid item>
                    <Button
                      id="btnRevokeAlias"
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={this.handleOpenRevoke}
                    >
                      Eliminar
                  </Button>
                  </Grid>
                }
              </Grid>
            </Grid>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(useStyles)(AliasInfo));
