import React from "react";
import Services from "../../services/AliasService";
import {
  CircularProgress,
  Container,
  Grid,
  TextField,
  Button,
  Breadcrumbs,
  Typography,
  GridList,
  GridListTile,
  Card,
  CardContent,
} from "@material-ui/core";
import Modal from "../../components/Modal/Modal";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import SelectDocumentType from "../../components/Selects/SelectDocumentType";
import MaterialTable from "material-table";
import Commons from "../../util/Commons";
import ListAltIcon from '@material-ui/icons/ListAlt';
import DeleteIcon from '@material-ui/icons/Delete';
import AliasInfo from "./AliasInfo";
import ConfirmChangeStatus from "../../components/ConfirmChangeStatus/ConfirmChangeStatus";

const useStyles = Commons.styles.getStyles();
const tableOptions = Commons.materialTable.getOptions(true, true, true, 7);

class Alias extends React.Component {
  constructor() {
    super();
    tableOptions.selection = true;
    tableOptions.selectionProps = rowData => {
      const selectable = rowData.statusAlias !== 'ELIMINADO'
      rowData.tableData.disabled = !selectable;
      return {
        color: 'primary',
        disabled: !selectable
      }
    }

    this.state = {
      loading: false,
      data: null,
      selectedRow: [],
      modalIsOpen: false,
      modalMessage: "",
      isInfoOpen: false,
      isRevokeMultiple: false,
      form: {
        documentType: "CC",
        document: "",
      },
    };
    this.baseState = this.state;
  }

  handleCloseModal = (e) => {
    this.setState({ modalIsOpen: false });
  };

  handleCloseInfo = (e) => {
    this.setState({ isInfoOpen: false });
  };

  handleCloseRevokeMultiple = (e) => {
    this.setState({ isRevokeMultiple: false });
  };

  handleEditRow = (selectedRow) => {
    this.setState({
      selectedRow: [selectedRow],
      isInfoOpen: true,
    });
  };

  handleRevokeRows = (selectedRows) => {

    this.setState({
      selectedRow: selectedRows,
      isRevokeMultiple: true,
    });
  };

  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  resetForm = () => {
    this.setState(this.baseState);
    this.setState({ loading: false });
  };

  handleSubmit = async () => {
    this.setState({ loading: true, data: null });
    const response = await Services.alias.findByTypeAndDoc(
      this.state.form,
      this.props.userName
    );
    if (response.success && response.data != null) {
      this.setState({
        loading: false,
        data: response.data,
      });
    } else {
      this.setState({
        loading: false,
        modalIsOpen: true,
        modalMessage: response.message,
      });
    }
  };

  handleChangeStatus = async (justification) => {
    this.setState({ loading: true });
    const response = await Services.alias.changeStatus(
      justification, this.state.selectedRow[0].aid
    );
    this.setState({
      ...this.state,
      loading: false,
      modalIsOpen: true,
      modalMessage: response.message,
    });
    if (response.success) {
      this.handleSubmit();
      this.setState({
        ...this.state,
        isInfoOpen: false,
      });
    }
  };

  handleRevoke = async (justification) => {
    this.setState({ loading: true });
    const response = await Services.alias.revoke(
      justification, this.state.selectedRow[0].aid
    );
    this.setState({
      ...this.state,
      loading: false,
      modalIsOpen: true,
      modalMessage: response.message,
    });
    if (response.success) {
      this.handleSubmit();
      this.setState({
        ...this.state,
        isInfoOpen: false,
      });
    }
  };

  handleRevokeMultiple = async (justification) => {
    this.setState({ loading: true });
    const response = await Services.alias.revokeMultiple(
      justification, this.state.selectedRow
    );
    this.setState({
      ...this.state,
      loading: false,
      modalIsOpen: true,
      modalMessage: response.message,
    });
    if (response.success) {
      this.handleSubmit();
      this.setState({
        ...this.state,
        isRevokeMultiple: false,
      });
    }
  };

  render() {
    const { classes } = this.props;

    if (this.state.modalIsOpen) {
      return (
        <Modal
          isOpen={this.state.modalIsOpen}
          onClose={this.handleCloseModal}
          title="Alias"
          message={this.state.modalMessage}
        />
      );
    }

    if (this.state.isRevokeMultiple) {
      return (
        <Container component="main" maxWidth="xs">
          <ConfirmChangeStatus
            open={this.state.isRevokeMultiple}
            handleClose={this.handleCloseRevokeMultiple}
            handleAccept={this.handleRevokeMultiple}
            page={"ALIAS"}
            process={"ELIMINAR ALIAS"}
            message="Seleccione la justificación para eliminar los alias"
            alertMessage={`Al hacer la eliminación masiva del registro de los alias se bloquea el ingreso de ${this.state.selectedRow[0].identityName} en todos los canales que tienen asociado el registro a eliminar. ¿Desea continuar?`}
          ></ConfirmChangeStatus>
        </Container>
      );
    }

    if (this.state.loading === true && !this.state.data) {
      return (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Breadcrumbs className={classes.breadcrumbs}>
          <Typography variant="h5" color="primary">
            SUID
          </Typography>
          <Typography variant="h5" color="textPrimary">
            Alias
          </Typography>
        </Breadcrumbs>

        <Container component="main" maxWidth="sm">
          <Grid container className={classes.container}>
            <Grid item xs={6}>
              <SelectDocumentType
                value={this.state.form.documentType}
                onChange={this.handleChange}
                name="documentType"
              />
            </Grid>
            <Grid item xs={4} className={classes.formControl}>
              <TextField
                id="document"
                name="document"
                label="Documento"
                onChange={this.handleChange}
                value={this.state.form.document}
              />
            </Grid>
            <Grid item xs={1} className={classes.formControl}>
              <Button
                id="btnSearchAlias"
                variant="contained"
                color="primary"
                size="small"
                onClick={this.handleSubmit}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </Container>
        {this.state.data && (
          <GridList cellHeight="auto" cols={5}>
            <GridListTile className={classes.gridList}
              key="identityInfo" cols={1} style={{ height: 'auto' }}>
              <Card variant="outlined">
                <CardContent>
                  <TextField
                    inputProps={{
                      readOnly: true,
                      style: { textAlign: "center" },
                    }}
                    size="small"
                    fullWidth
                    id="identityName"
                    label="Nombre"
                    value={this.state.data[0].identityName + ""}
                  />
                  <TextField
                    inputProps={{
                      readOnly: true,
                      style: { textAlign: "center" },
                    }}
                    size="small"
                    fullWidth
                    id="identityDocumentType"
                    label="Tipo de documento"
                    value={this.state.data[0].identityDocumentType + ""}
                  />
                  <TextField
                    inputProps={{
                      readOnly: true,
                      style: { textAlign: "center" },
                    }}
                    size="small"
                    fullWidth
                    id="identityDocument"
                    label="Número documento"
                    value={this.state.data[0].identityDocument + ""}
                  />
                  <TextField
                    inputProps={{
                      readOnly: true,
                      style: { textAlign: "center" },
                    }}
                    size="small"
                    fullWidth
                    id="identityType"
                    label="Tipo de identidad"
                    value={this.state.data[0].identityType + ""}
                  />

                </CardContent>
              </Card>
            </GridListTile>
            {!this.state.isInfoOpen &&
              <GridListTile className={classes.gridList}
                key="aliasList" cols={3} style={{ height: 'auto' }}>
                <Container component="main" maxWidth="md" className={classes.table}>
                  <MaterialTable
                    title=""
                    icons={Commons.materialTable.getIcons()}
                    data={this.state.data}
                    options={tableOptions}
                    localization={Commons.materialTable.getLocalization()}
                    columns={[
                      {
                        title: "Consumidor asociado",
                        field: "app",
                      },
                      {
                        title: "Estado",
                        field: "statusConsumer",
                      },
                      {
                        title: "Estado del alias",
                        field: "statusAlias",
                      }]}
                    actions={[
                      {
                        position: "row",
                        icon: () => <ListAltIcon />,
                        tooltip: "Ver detalles",
                        onClick: (event, rowData) => {
                          if (rowData) {
                            this.handleEditRow(rowData);
                          }
                        },
                      },
                      {
                        icon: () => <DeleteIcon />,
                        tooltip: "Eliminar alias seleccionados",
                        onClick: (event, data) => {
                          if (data.length > 0) {
                            this.handleRevokeRows(data);
                          }
                        },
                      },
                    ]}
                  />
                </Container>
              </GridListTile>
            }
            {this.state.isInfoOpen &&
              <GridListTile className={classes.gridList}
                key="aliasInfo" cols={3} style={{ height: 'auto' }}>
                <AliasInfo
                  data={this.state.selectedRow[0]}
                  onClose={this.handleCloseInfo}
                  onChangeStatus={this.handleChangeStatus}
                  onRevoke={this.handleRevoke}
                />
              </GridListTile>
            }
          </GridList>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(useStyles)(Alias));
