import React from "react";
import Services from "../../services/ConsumerService";
import Select from "@material-ui/core/Select";
import { InputLabel } from "@material-ui/core";

class SelectConsumer extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [{
        id: "",
        acronym: "",
        name: "",
      }],
    };
  }

  async componentDidMount() {
    const response = await Services.consumer.getConsumers("");

    if (response.success && response.data != null) {
      this.setState({
        data: this.state.data.concat(response.data),
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <InputLabel htmlFor="consumer" id="consumerLabel">Consumidor</InputLabel>
        <Select
          native
          labelId="consumerLabel"
          name="consumer"
          id="consumer"
          value={this.props.value}
          onChange={this.props.onChange}
          displayEmpty={true}
          fullWidth
        >
          {this.state.data.map((data) => (
            <option key={data.id} value={data.id}>
              {data.acronym}_{data.name}
            </option>
          ))}
        </Select>
      </React.Fragment>
    );
  }
}
export default SelectConsumer;
