import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Navbar from "./components/Navbar/Navbar";
import Consumer from "./pages/Consumer/Consumer";
import Home from "./pages/Home/Home";
import Identity from "./pages/Identity/Identity";
import Alias from "./pages/Alias/Alias";
import AdminLog from "./pages/AdminLog/AdminLog";
import DocumentType from "./pages/DocumentType/DocumentType";
import Justification from "./pages/Justification/Justification";
import AADService from "./services/AADService";
import TransactionalLog from "./pages/TransactionalLog/TransactionalLog";
import ProcessingLog from "./pages/ProcessingLog/ProcessingLog";
import Segment from "./pages/Segment/Segment";
import FunctionalLog from "./pages/FunctionalLog/FunctionalLog";
import Contact from "./pages/Contact/Contact";
import Params from "./pages/params";
import IdleTimer from 'react-idle-timer'

class App extends React.Component {


  constructor(props) {
    super(props)
    this.idleTimer = null
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.state={ready: false}
  }

  componentDidMount() {
    AADService.auth.init().then(() => this.setState({ ready: true }));
  }

  handleOnIdle(event) {
    AADService.auth.logout();
  }

  render() {
    if  (!this.state.ready){
      return null;
    } 
    return (
      
      <BrowserRouter >
         <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000*60*5}
          onIdle={this.handleOnIdle}
        />
        <Header />
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home}></Route>
          {AADService.auth.hasAccessToResource('Identidad', 'Buscar') &&
            <Route
              exact
              path="/identity"
              render={() => <Identity />}
            ></Route>
          }
          {AADService.auth.hasAccessToResource('Alias', 'Buscar') &&
            <Route
              exact
              path="/alias"
              render={() => <Alias />}
            ></Route>
          }
          {AADService.auth.hasAccessToResource('Consumidores', 'Consultar') &&
            <Route
              exact
              path="/consumer"
              render={() => <Consumer />}
            ></Route>
          }
          {AADService.auth.hasAccessToResource('LogAdministrativo', 'Buscar') &&
            <Route
              exact
              path="/adminLog"
              render={() => <AdminLog />}
            ></Route>
          }
          {AADService.auth.hasAccessToResource('LogTransaccional', 'Buscar') &&
            <Route
              exact
              path="/transactionalLog"
              render={() => <TransactionalLog />}
            ></Route>
          }
          {AADService.auth.hasAccessToResource('LogTransaccional', 'Buscar') &&
            <Route
              exact
              path="/processingLog"
              render={() => <ProcessingLog />}
            ></Route>
          }
          {AADService.auth.hasAccessToResource('TiposDocumentos', 'Consultar') &&
            <Route
              exact
              path="/documentTypes"
              render={() => <DocumentType />}
            ></Route>
          }
          {AADService.auth.hasAccessToResource('Justificaciones', 'Consultar') &&
            <Route
              exact
              path="/justifications"
              render={() => (
                <Justification />
              )}
            ></Route>
          }
          {AADService.auth.hasAccessToResource('Segmentos', 'Consultar') &&
            <Route
              exact
              path="/segments"
              render={() => (
                <Segment />
              )}
            ></Route>
          }
          {AADService.auth.hasAccessToResource('Contactos', 'Buscar') &&
            <Route
              exact
              path="/contacts"
              render={() => (
                <Contact />
              )}
            ></Route>
          }
          {AADService.auth.hasAccessToResource('Params', 'LeerParams') &&
            <Route
              exact
              path="/paramstarjetas"
              render={() => <Params />}
            ></Route>
          }
          {AADService.auth.hasAccessToResource('LogFuncional', 'Buscar') &&
            <Route
              exact
              path="/functional"
              render={() => <FunctionalLog />}
            ></Route>
          }
          <Route component={Home}></Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
