import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MaterialTable from "material-table";
import Commons from "../../util/Commons";
import AADService from "../../services/AADService";

export default function TransactionalLogResults(open, data, handleClose) {
  return (
    <div>
    
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogContent>
          <MaterialTable
            title="Resultados Log Transaccional"
            icons={Commons.materialTable.getIcons()}
            data={data}
            options={
              Commons.materialTable.getOptions(true, true, AADService.auth.hasAccessToResource('LogTransaccional', 'Exportar'))
            }
            localization={Commons.materialTable.getLocalization()}
            columns={[
              {
                title: "Consumidor",
                field: "consumer",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "CID",
                field: "cid",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Tipo de documento",
                field: "documentType",
                editable: "never",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Documento",
                field: "document",
                editable: "never",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Nombre",
                field: "fullName",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Código MDM",
                field: "mdmCode",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "AID",
                field: "aid",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Transacción",
                field: "transaction",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Código respuesta",
                field: "responseCode",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Mensaje respuesta",
                field: "responseMessage",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Fecha",
                field: "logDate",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Hora",
                field: "logTime",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: "Message Id",
                field: "messageId",
                width: 100,
                headerStyle: {
                  fontWeight: "bold",
                },
              },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={handleClose}
            color="primary"
            autoFocus
          >
            Regresar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
